/**
 * Created by osirvent on 14/03/2016.
 */
angular
    .module('annexaApp')
    .factory('TableFilter',['LocalDataFactory', 'HelperService', 'CommonService', 'RestService', 'GlobalDataFactory', function(LocalDataFactory, HelperService, CommonService, RestService, GlobalDataFactory) {

        var TableFilter = function(numTableRows, classNumRows) {
            this.localdata = undefined;
            this.showLegend = true;
            this.showFilterBar = true;
            this.numTableRows = numTableRows == undefined ? 4 : numTableRows;
            this.classNumRows = classNumRows == undefined ? 3 : classNumRows;
            this.copyPropertiesSaved = function(newTableFilter){
                for(var key in newTableFilter) {
                    if(key != 'localdata' && key != 'showLegend' && key != 'showFilterBar' && key != 'numTableRows' && key != 'classNumRows' && key != 'copyPropertiesSaved' && typeof newTableFilter[key] != "function") {
                        this[key] = newTableFilter[key];
                    }
                }
            };
        }

        var TableFilter = function(showLegend, numTableRows, classNumRows) {
            this.localdata = undefined;
            if(showLegend != null && showLegend != undefined) {
                this.showLegend = showLegend;
            }else{
                this.showLegend = true;
            }
            this.showFilterBar = true;
            this.numTableRows = numTableRows == undefined ? 4 : numTableRows;
            this.classNumRows = classNumRows == undefined ? 3 : classNumRows;
            this.copyPropertiesSaved = function(newTableFilter){
                for(var key in newTableFilter) {
                    if(key != 'localdata' && key != 'showLegend' && key != 'showFilterBar' && key != 'numTableRows' && key != 'classNumRows' && key != 'copyPropertiesSaved' && typeof newTableFilter[key] != "function") {
                        this[key] = newTableFilter[key];
                    }
                }
            };
        }

        var selectArrayElement = function(element, idSelect, pushElement){
            var self  = this;
            var exist = false;
            if(element && element.listValues){
                for(var i=0; i<element.listValues.length && !exist; i++){
                    if(element.listValues[i].id == idSelect){
                        if(pushElement) {
                            element.model.push(element.listValues[i]);
                        } else {
                            element.model = element.listValues[i];
                        }
                        exist = true;
                    }
                }
            }

        };

        var selectElement = function(element, selectedItems, key){
            if(element.type == 'select-multiple' && selectedItems) {
                element.model = [];
                var selectedItemsAux = selectedItems;
                if(selectedItems[key].value){
                    selectedItemsAux = selectedItems[key].value;
                }
                _.forEach(selectedItemsAux, function(item) {
                    selectArrayElement(element, item.id, true);
                })
                element.prefilter = selectedItems[key].value;
            } else {
                if (selectedItems && selectedItems[key] && selectedItems[key].value) {
                    selectArrayElement(element, selectedItems[key].value);
                } else if (element.listValues) {
                    element.model = element.listValues[0];
                }
            }
        };

        var searchListOfValuesElement = function(element, selectedItems, key, language, filter){
            if(element.isTranslate){
            	if(element.originValues == 'FamilyProcedure' && GlobalDataFactory.familyProcedures && GlobalDataFactory.familyProcedures.length > 0){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.familyProcedures), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'Template' && GlobalDataFactory.templates && GlobalDataFactory.templates.length > 0){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.templates), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'TaskType' && GlobalDataFactory.taskTypes && GlobalDataFactory.taskTypes.length > 0){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.taskTypes), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'Section' && GlobalDataFactory.sections && GlobalDataFactory.sections.length > 0){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.sections), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'DocumentType' && GlobalDataFactory.documentTypes && GlobalDataFactory.documentTypes.length > 0){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.documentTypes), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterDiligenceType' && GlobalDataFactory.registerDiligenceTypes && GlobalDataFactory.registerDiligenceTypes.length > 0){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.registerDiligenceTypes), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterEntryChannel' && GlobalDataFactory.allRegisterEntryChannelsInput && GlobalDataFactory.allRegisterEntryChannelsInput.length > 0 && filter == 'INPUT'){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.allRegisterEntryChannelsInput), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterEntryChannel' && GlobalDataFactory.allRegisterEntryChannelsOutput && GlobalDataFactory.allRegisterEntryChannelsOutput.length > 0 && filter == 'OUTPUT'){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.allRegisterEntryChannelsOutput), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterEntryDocumentType' && GlobalDataFactory.registerEntryDocumentTypesInput && GlobalDataFactory.registerEntryDocumentTypesInput.length > 0 && filter == 'INPUT'){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.registerEntryDocumentTypesInput), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterEntryDocumentType' && GlobalDataFactory.registerEntryDocumentTypesOutput && GlobalDataFactory.registerEntryDocumentTypesOutput.length > 0 && filter == 'OUTPUT'){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.registerEntryDocumentTypesOutput), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterEntryClassification' && GlobalDataFactory.registerEntryClassificationsInput && GlobalDataFactory.registerEntryClassificationsInput.length > 0 && filter == 'INPUT'){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.registerEntryClassificationsInput), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterEntryClassification' && GlobalDataFactory.registerEntryClassificationsOutput && GlobalDataFactory.registerEntryClassificationsOutput.length > 0 && filter == 'OUTPUT'){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.registerEntryClassificationsOutput), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else{
	                RestService.findAll(element.originValues)
	                    .then(function(objects){
	                        element.listValues = HelperService.addAllSelect(JSOG.decode(objects.data), element.nameElement, language);
	                        selectElement(element, selectedItems, key);
	                    });
            	}
            }else{
            	if(element.originValues == 'FamilyProcedure' && GlobalDataFactory.familyProcedures && GlobalDataFactory.familyProcedures.length > 0){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.familyProcedures), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'Template' && GlobalDataFactory.templates && GlobalDataFactory.templates.length > 0){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.templates), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'TaskType' && GlobalDataFactory.taskTypes && GlobalDataFactory.taskTypes.length > 0){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.taskTypes), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'Section' && GlobalDataFactory.sections && GlobalDataFactory.sections.length > 0){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.sections), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'DocumentType' && GlobalDataFactory.documentTypes && GlobalDataFactory.documentTypes.length > 0){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.documentTypes), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterDiligenceType' && GlobalDataFactory.registerDiligenceTypes && GlobalDataFactory.registerDiligenceTypes.length > 0){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.registerDiligenceTypes), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterEntryChannel' && GlobalDataFactory.allRegisterEntryChannelsInput && GlobalDataFactory.allRegisterEntryChannelsInput.length > 0 && filter == 'INPUT'){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.allRegisterEntryChannelsInput), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterEntryChannel' && GlobalDataFactory.allRegisterEntryChannelsOutput && GlobalDataFactory.allRegisterEntryChannelsOutput.length > 0 && filter == 'OUTPUT'){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.allRegisterEntryChannelsOutput), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterEntryDocumentType' && GlobalDataFactory.registerEntryDocumentTypesInput && GlobalDataFactory.registerEntryDocumentTypesInput.length > 0 && filter == 'INPUT'){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.registerEntryDocumentTypesInput), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterEntryDocumentType' && GlobalDataFactory.registerEntryDocumentTypesOutput && GlobalDataFactory.registerEntryDocumentTypesOutput.length > 0 && filter == 'OUTPUT'){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.registerEntryDocumentTypesOutput), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterEntryClassification' && GlobalDataFactory.registerEntryClassificationsInput && GlobalDataFactory.registerEntryClassificationsInput.length > 0 && filter == 'INPUT'){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.registerEntryClassificationsInput), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else if(element.originValues == 'RegisterEntryClassification' && GlobalDataFactory.registerEntryClassificationsOutput && GlobalDataFactory.registerEntryClassificationsOutput.length > 0 && filter == 'OUTPUT'){
            		element.listValues = HelperService.addAllSelect(angular.copy(GlobalDataFactory.registerEntryClassificationsOutput), element.nameElement, language);
                    selectElement(element, selectedItems, key);	
            	}else{
            		RestService.findAll(element.originValues)
            			.then(function(objects){
            				element.listValues = HelperService.addAllSelect(JSOG.decode(objects.data), element.nameElement, language);
            				selectElement(element, selectedItems, key);
            			});
            	}
            }
        };

        TableFilter.prototype.loadLocalData = function(localdata, selectedItems, filter, language) {
            var self = this;

            LocalDataFactory.getAllData(localdata)
                .then(function(data) {
                    self.localdata = data;
                    angular.forEach(self, function(element, key) {
                        if(key != 'localdata' && key != 'showLegend' && key != 'showFilterBar' && key != 'copyPropertiesSaved' && (element.type == 'select' || element.type == 'select-multiple')) {
                            if(element.dataType == 1) {

                                if (element.originAddAll) {
                                    element.listValues = HelperService.addAllSelectLocalData(self.localdata, element.originValues, element.nameElement, language);
                                    if(filter){
                                        element.listValues = $linq(element.listValues)
                                            .where(filter)
                                            .toArray();
                                    }
                                } else {
                                    element.listValues = HelperService.findFirstElementOfArrayWhere(self.localdata, 'name', element.originValues, undefined, element.nameElement, language);
                                    if(filter){
                                        element.listValues = $linq(element.listValues)
                                            .where(filter)
                                            .toArray();
                                    }
                                }
                                if(element.aditionalElements) {
                                    _.forEach(element.aditionalElements, function (aElement) {
                                        element.listValues.push(aElement);
                                    });
                                }

                                selectElement(element, selectedItems, key);
                            }else if(element.dataType == 2){
                                if(element.originValues) {
                                    searchListOfValuesElement(element, selectedItems, key, language, filter);
                                }
                            }else{
                                selectElement(element, selectedItems, key);
                            }
                        }
                    });
                });
        };
        
        TableFilter.prototype.addElement = function(name,type,order,label,dataType,data,addAll,nameElement,isTranslate,iconElement,language,aditional_elements) {
            var self = this;
            if(!isTranslate){
                isTranslate = false;
            }
            if(type=='select' || type == 'select-multiple' ) {
                if(dataType == 1 && self.localdata){
                    if(addAll) {
                        self[name] = new TableFilterItem(name,type, order, label, dataType, HelperService.addAllSelectLocalData(self.localdata, data, nameElement, language),data, addAll, iconElement, nameElement, isTranslate, aditional_elements);
                    } else {
                        self[name] = new TableFilterItem(name,type, order, label, dataType,HelperService.findFirstElementOfArrayWhere(self.localdata,'name',data,undefined, nameElement, language), data, addAll, iconElement, nameElement, isTranslate, aditional_elements);
                    }
                }else if(dataType == 2){
                    self[name] = new TableFilterItem(name, type, order, label, dataType, undefined, data, addAll, iconElement, nameElement, isTranslate, aditional_elements);
                }else if(dataType == 3){
                    if(data) {
                        self[name] = new TableFilterItem(name, type, order, label, dataType, data, undefined, addAll, iconElement, nameElement, isTranslate, aditional_elements);
                    }else{
                        self[name] = new TableFilterItem(name, type, order, label, dataType, undefined, undefined, addAll, iconElement, nameElement, isTranslate, aditional_elements);
                    }
                }else{
                    self[name] = new TableFilterItem(name, type, order, label, dataType, undefined, data, addAll, iconElement, nameElement, isTranslate, aditional_elements);
                }
            } else if(type=='select-tree') {
                var treeData = CommonService.getTreeData(data,language);
                self[name] = new TableFilterItem(name, type, order, label,undefined,treeData,undefined,undefined,undefined,undefined, false);
            } else {
                self[name] = new TableFilterItem(name, type, order, label, dataType, undefined, data, addAll, iconElement, nameElement, isTranslate);
            }

            return self[name];
        };

        var getTreeData = function (allNodes, language) {
            var treeData = { 0: [] };

            angular.forEach(allNodes, function (node, key) {
                var numSubNodes = $linq(allNodes).count("x => x.parent != null && x.parent.id == " + node.id);

                if(node.parent == null) {
                    treeData[0].push({ "id": node.id, "title": node[language], "size": numSubNodes, "parent": numSubNodes == 0 ? false : true })
                } else {
                    if(!(node.parent.id in treeData )) {
                        treeData[node.parent.id] = [];
                    }
                    treeData[node.parent.id].push({ "id": node.id, "title": node[language], "size": numSubNodes, "parent": numSubNodes == 0 ? false : true })
                }
            });

            return treeData;
        };

        var getTreeData1 = function (allNodes, language, index) {
            var treeData = {};

            if(index) {
                nodes = $linq(allNodes).where("x => x.parent != null && x.parent.id == " + index).toArray();
            } else {
                nodes = $linq(allNodes).where("x => x.parent == null").toArray();
                index = 0;
            }

            var arrNodes = [];
            angular.forEach(nodes, function (node, key) {
                var numSubNodes = $linq(allNodes).count("x => x.parent != null && x.parent.id == " + node.id);
                arrNodes.push({ "id": node.id, "title": node[language], "size": numSubNodes, "parent": numSubNodes == 0 ? false : true });
                if(numSubNodes > 0 ) {
                    treeData[node.id] = getTreeData(allNodes, language, node.id);
                }
            });

            if(index) {
                return arrNodes;
            } else {
                treeData[0] = arrNodes;
                return treeData;
            }
        }

        TableFilter.prototype.getElements = function() {
            var self  = this;
            var elements = []
            angular.forEach(self, function(element, key) {
                if(key != 'localdata' && key != 'showLegend' && key != 'showFilterBar' && key != 'numTableRows' && key != 'classNumRows' && key != 'copyPropertiesSaved') {
                    elements.push(element);
                }
            });

            return elements.sort(function(a,b) {
                return a.order - b.order;
            });
        };

        TableFilter.prototype.getShowLegend = function() {
            var self  = this;
           angular.forEach(self, function(element, key) {
                if(key = 'showLegend') {
                    return element;
                }
            });
            return true;

        };

        TableFilter.prototype.checkEmpty = function (novalidate) {
            var self = this;
            var empty = true;

            if(!novalidate) {
                novalidate = [];
            }

            angular.forEach(self, function (element, key) {
                if(key != 'localdata' && key != 'showLegend' && key != 'showFilterBar' && key != 'copyPropertiesSaved') {
                    switch (element.type) {
                        case 'dateRange':
                        case 'text':
                            if(element.model && novalidate.indexOf(key) == -1) {
                                empty = false;
                            }
                            break;
                        case 'select':
                            if(element.model && element.model.id && novalidate.indexOf(key) == -1) {
                                empty = false;
                            }
                            break;
                        case 'select-multiple':
                            if(element.model && element.model.id && novalidate.indexOf(key) == -1) {
                                empty = false;
                            }
                    }
                }
            })

            return empty;
        }

        TableFilter.prototype.getFilterCall = function() {
            var self = this;
            var filterCall = {};

            angular.forEach(self, function(element, key) {
                if(key != 'localdata' && key != 'showLegend' && key != 'showFilterBar' && key != 'copyPropertiesSaved') {
                    if(!element.filterCallAux) {
                        switch (element.type) {
                            case 'text':
                                filterCall[key] = undefined;
                                if(element.model) {
                                    filterCall[key] = element.model;
                                }
                                break;
                            case 'select-multiple':
                                filterCall[key] = undefined;
                                filterCall[key] = element.model;
                                break;
                            case 'select':
                                filterCall[key] = undefined;
                               if(element.model && element.model.id) {
                                   filterCall[key] = {id: element.model.id};
                                }
                                break;
                            case 'select-tree':
                                filterCall[key] = undefined;
                                if(element.model && element.model.$selected && element.model.$selected.id) {
                                    filterCall[key] = {id: element.model.$selected.id};
                                }
                        }
                    }
                }
            });
            
            return filterCall;
        };

        TableFilter.prototype.getFilterCallAux = function() {
            var self = this;
            var filterCall = {};

            angular.forEach(self, function(element, key) {
                if(key != 'localdata' && key != 'showLegend' && key != 'showFilterBar' && key != 'copyPropertiesSaved') {
                    if(element.filterCallAux) {
                        switch (element.type) {
                            case 'text':
                                filterCall[key] = undefined;
                                if(element.model) {
                                    filterCall[key] = element.model;
                                }
                                break;
                            case 'select-multiple':
                                filterCall[key] = undefined;
                                if((!element.model || element.model.length == 0) && element.prefilter) {
                                    element.model = [];
                                    _.forEach(element.prefilter, function(item) {
                                        selectArrayElement(element, item.id, true);
                                    });
                                    element.prefilter = undefined;
                                }
                                filterCall[key] = element.model;
                                break;
                            case 'select':
                                filterCall[key] = undefined;
                                if(element.model && element.model.id) {
                                    filterCall[key] = {id: element.model.id};
                                }
                                break;
                            case 'select-tree':
                                filterCall[key] = undefined;
                                if(element.model && element.model.$selected && element.model.$selected.id) {
                                    filterCall[key] = {id: element.model.$selected.id};
                                }
                                break;
                            case 'dateRange':
                                filterCall[key + 'From'] = undefined;
                                filterCall[key + 'To'] = undefined;
                                if(element.model) {
                                    if(element.model.dataFrom) {
                                        var dateAux = element.model.dataFrom;
                                        filterCall[key + 'From'] = new Date(Date.UTC(dateAux.getFullYear(),dateAux.getMonth(),dateAux.getDate(),00,00,00));
                                    }
                                    if(element.model.dataTo) {
                                        var dateAux = element.model.dataTo;
                                        filterCall[key + 'To'] = new Date(Date.UTC(dateAux.getFullYear(),dateAux.getMonth(),dateAux.getDate(),23,59,59));
                                    }
                                }
                        }
                    }
                }
            });

            return filterCall;
        };

        TableFilter.prototype.clearFilters = function(target, params) {
            var self = this;
            var elements = self.getElements();
            if (elements.length != 0) {
                var prefilter = undefined;
                if(params && params.length > 0){
                    prefilter = {};
                    angular.forEach(params, function(value, key){
                        prefilter[value.id] = {value:value.value};
                    });
                }
                for (i = 0; i < elements.length; i++) {
                    switch (elements[i].type) {
                        case 'select-multiple':
                            if(elements[i].listValues && elements[i].listValues.length > 0) {
                                if(prefilter) {
                                    if (prefilter[elements[i].name]) {
                                        selectElement(elements[i], prefilter, elements[i].name);
                                    }else{
                                        elements[i].model = elements[i].listValues[0];
                                    }
                                }else {
                                    elements[i].model = elements[i].listValues[0];
                                }
                            }else{
                                elements[i].model = '';
                            }
                            break;
                        case 'select':
                            if(elements[i].listValues && elements[i].listValues.length > 0) {
                                if(prefilter) {
                                    if (prefilter[elements[i].name]) {
                                        selectElement(elements[i], prefilter, elements[i].name);
                                    }else{
                                        elements[i].model = elements[i].listValues[0];
                                    }
                                }else {
                                    elements[i].model = elements[i].listValues[0];
                                }
                            }else{
                                elements[i].model = '';
                            }
                            break;
                        case 'text':
                            elements[i].model = '';
                            break;
                        case 'dateRange':
                            elements[i].model = undefined;
                            break;
                    }
                }
            }
            
            if(target) {
                angular.element(target).collapse('hide');
            }
        }

        TableFilter.prototype.show = function () {
            var self = this;
            self.showFilterBar = true;
        }

        TableFilter.prototype.hide = function () {
            var self = this;
            self.showFilterBar = false;
        }

        return TableFilter;
    }]);