/**
 * Created by osirvent on 26/10/2016.
 */
var editableTableColumn = function (key, title, width, printFunc) { //, type, model) {
    this.key = key;
    this.title = title;
    this.style = { width: width } || {};
    this.type = '';
    this.print = printFunc;
    this.multiple = false

    this.setSelectColumn = function(label, elements, checkFunction, multiple) {
        this.multiple = multiple || false;
        if(this.multiple) {
            this.type = 'select-multiple';
        } else {
            this.type = 'select';
        }
        this.elementLabel = label;
        this.elements = elements;

        this.elementCheckFunction = checkFunction;
        return this;
    }

    this.setNumberColumn = function(lengthMaxItems, length) {
        this.type = 'number'
        if(!lengthMaxItems) {
            this.elementLength = length;
        }

        return this;
    }

    this.setLoadUserColumn = function(search, print) {
        this.type = 'load-user';
        this.elementSearch = search;
        this.elementPrint = print;

        return this;
    }
}

var editableTableColumnDefaultProfile = function (key, title, width, printFunc) {
    this.key = key;
    this.title = title;
    this.style = { width: width } || {};
    this.type = '';
    this.print = printFunc;
    this.multiple = false;
    this.allProfiles = '';

    this.setSelectColumn = function(label, elements, checkFunction, multiple, elementsFunction, propiedad) {
        this.multiple = multiple || false;
        if(this.multiple) {
            this.type = 'select-multiple';
        } else {
            this.type = 'select';
        }
        this.elementLabel = label;
        this.elements = elements;
        this.elementCheckFunction = checkFunction;

        if(propiedad == 'falso'){
            if(elementsFunction){
                this.getElements = elementsFunction;
            }
            else{
                this.getElements = function(){
                    return elements;
                };
            }
        }
        else{
            this.elements = elements;
        }

        this.allProfiles = propiedad;
        return this;
    }

    this.setNumberColumn = function(lengthMaxItems, length) {
        this.type = 'number'
        if(!lengthMaxItems) {
            this.elementLength = length;
        }

        return this;
    }

    this.setLoadUserColumn = function(search, print) {
        this.type = 'load-user';
        this.elementSearch = search;
        this.elementPrint = print;

        return this;
    }
}

var editableTable = function (id, title, addTitle, addFunction, updateFunction, removeFunction, containerObject) {
    this.id = id || new Date().getTime() + '';
    this.title = title;
    this.inserted = {};
    this.items = []
    this.columns = [];
    this.addFunction = addFunction;
    this.updateFunction = updateFunction;
    this.removeFunction = removeFunction;
    this.addTitle = addTitle || 'global.literals.add';
    this.containerObject = containerObject;

    this.addColumn = function(column) {
        this.columns.push(column);
        return this;
    }

    this.addColumns = function (columns) {
        var self = this;
        angular.forEach(columns, function (column) {
            self.columns.push(column);
        });

        return this;
    }

    this.setItems = function (items) {
        this.items = items;

        return this;
    }
}
