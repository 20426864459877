/**
 * Created by dpardell on 09/11/2016.
 */
angular.module('annexaApp')
    .directive('documentFileOrTemplate',['$compile', '$rootScope', 'HelperService', function($compile, $rootScope, HelperService) {
        var getTemplate = function(enabledField, languages, labelFile, labelTemplate, scope) {
            var template = '';
            template += '<div class="row" >';
            template += '   <div class="col-md-12">';
            template += '       <ul class="nav nav-tabs annexaTabs">';
            template += '           <li class=" ng-class: {\'active\' : ngModel[inputType] == keyFile}" ng-show="ngModel.showFile">';
            template += '               <div class="radio">';
            template += '                   <span class="ui-check">';
            template += '                       <input tabindex="0" type="radio" name="radioType" id="fileOption1" value="{{keyFile}}" ng-model="ngModel[inputType]" ng-checked="ngModel[inputType] == keyFile" ng-disabled="disabled"/>';
            template += '                       <i class="dark-white"></i>';
            template += '                   </span>';
            template += '                   <label for="fileOption1" translate="'+labelFile+'">'+labelFile+'</label>';
            template += '               </div>';
            template += '           </li>';
            template += '           <li class=" ng-class: {\'active\' : ngModel[inputType] == keyTemplate}"  ng-show="ngModel.showTemplate">';
            template += '               <div class="radio">';
            template += '                   <span class="ui-check">';
            template += '                       <input tabindex="0"  type="radio" name="radioType" id="fileOption2" value="{{keyTemplate}}" ng-model="ngModel[inputType]" ng-checked="ngModel[inputType] == keyTemplate" ng-disabled="disabled"/>';
            template += '                       <i class="dark-white"></i>';
            template += '                   </span>';
            template += '                   <label for="fileOption2" translate="'+labelTemplate+'">'+labelTemplate+'</label>';
            template += '               </div>';
            template += '           </li>';
            template += '       </ul>';
            template += '   </div>';
            template += '</div>';
            template += '<div class="p-x-9375" >';
            template += '   <div class="row tab-panel" id="listCircuitsPredef" ng-show="ngModel[inputType] == keyFile && ngModel.showFile">';
            template += '       <label for="newDoc-{{keyFile}}" class="sr-only">{{ \'global.literals.SelectUploadFile\' | translate }}</label>';
            template += '       <input type="file" ng-model="ngModel[keyFile]" class="form-control" id="newDoc-{{keyFile}}" name="{{keyFile}}" accept="{{options.data.accept}}" onchange="angular.element(this).scope().onSelected(this)" ng-disabled="disabled">';
            template += '   </div>';
            template += '   <div class="row tab-panel" id="listCircuitsPerso" ng-show="ngModel[inputType] == keyTemplate && ngModel.showTemplate">';
            template += '       <ui-tree-select ng-model="ngModel[keyTemplate]" data="data" required="required" only-last-level-click="onlyLastLevelClick" change="change" disabled="disabled" extra-change="fieldOptions"></ui-tree-select>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                ngModel: '=',
                data: '=',
                change: '=',
                required: '=',
                onlyLastLevelClick: '=',
                changeFilter: '&',
                keyFile:'=',
                keyTemplate:'=',
                types:'=',
                inputType:'=',
                onSelected:'=',
                fieldOptions: '='
            },
            link: function(scope, element, attr) {
                element.html(getTemplate(attr['enabledField'], $rootScope.app.languagedef, attr['labelFile'], attr['labelTemplate'], scope)).show();
                scope.disabled = ((scope.fieldOptions && scope.fieldOptions.templateOptions && scope.fieldOptions.templateOptions.disabled)?true:false);
                $compile(element.contents())(scope);
            }
        }
}]);