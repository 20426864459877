/**
 * Created by osirvent on 01/08/2016.
 */
angular.module('annexaApp')
    .directive('annexaCustomField',['$compile', '$filter', 'Language', function ($compile, $filter, Language) {
        var getTemplate = function(scope) {
            var template = '';
            template += '<div class="box">';
            template += '   <div class="box-header p-t-0 p-r-0">';
            template += '     <h2 translate="global.literals.customFields">NEW Custom fields</h2>';
            template += '   </div>';
            template += '   <div class="p-t-sm sortable-container clearfix redraw-floating" sv-root sv-part="customFieldsDefinition.customFieldsProcedure" sv-on-sort="customFieldsDefinition.sort($item, $partFrom, $partTo, $indexFrom, $indexTo)">';
            template += '       <div class="col-xs-12 col-sm-12 m-b-xs bg-faded" data-ng-repeat="tramCustomField in customFieldsDefinition.customFieldsProcedure | orderBy:\'viewOrder\'" sv-element>';
            template += '         <div class="row  p-t-sm">';
            template += '           <div class="col-xs-5 col-lg-4 p-r-0" sv-handle>';
            template += '               <i class="p-t-sm m-t-xs m-r-sm fa fa-bars grey-pencil" aria-hidden="true"></i>';
            template += '               <label class="label-strong p-y-sm m-b-xs">{{tramCustomField.customField[customFieldsDefinition.languageColumn]}}</label>';
            template += '           </div>';
            template += '           <div class="col-xs-6 col-lg-5 col-xl-6 p-r-0">';
            template += '               <ui-select ng-model="tramCustomField.requiredString" theme="bootstrap" ng-change="customFieldsDefinition.updateFunction(tramCustomField)">';
            template += '                   <ui-select-match >{{$select.selected.description | translate}}</ui-select-match>';
            template += '                   <ui-select-choices repeat="item.id as item in customFieldsDefinition.customFieldRequiredList | filter: $select.search" >';
            template += '                       <div ng-bind-html="item.description | translate"></div>';
            template += '                   </ui-select-choices>';
            template += '               </ui-select>';
            template += '           </div>';
            template += '           <div class="col-xs-2 col-lg-3 col-xl-2">';
            template += '               <p class="m-b-0 pull-right p-r-xs m-t-sm">';
            template += '                   <a href="" ng-hide="disableElement(tramCustomField.customField)" ng-click="customFieldsDefinition.editModalFunction($index)" class="remove-button "><i class="fa fa-fw fa-pencil grey-pencil" title="{{\'global.literals.edit\' | translate}}"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span></a>';
            template += '                   <a href="" ng-show="disableElement(tramCustomField.customField)" ng-click="customFieldsDefinition.editModalFunction($index)" class="remove-button "><i class="fa fa-fw fa-eye grey-pencil" title="{{\'global.literals.see\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.see\' | translate}}</span></a>';
            template += '                   <a href="" ng-click="customFieldsDefinition.removeFunction($index)" class="remove-button m-t-xs"><i class="fa fa-fw fa-remove grey-pencil" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></a>';
            template += '               </p>';
            template += '           </div>';
            template += '         </div>';
            template += '         <div class="row p-t-xs">';
            template += '           <div class="col-xs-4">';
            template += '               <label class="label-strong small p-y-sm m-b-xs">Valor per defecte</label>'
            template += '           </div>';
            template += '           <div class="col-xs-8 flexboxData outLabel">';
            template += '               <annexa-custom-field-component ';
            template += '                   key="{{tramCustomField.customField.id}}" ';
            template += '                   frontend-type="{{tramCustomField.customField.frontendType}}" ';
            template += '                   backend-type="{{tramCustomField.customField.backendType}}" ';
            template += '                   label="" ';
            template += '                   model="tramCustomField.value" ';
            template += '                   min-length="tramCustomField.customField.minLength" ';
            template += '                   max-length="tramCustomField.customField.maxLength" ';
            template += '                   decimal-positions="tramCustomField.customField.decimalPositions" ';
            template += '                   required="false" ';
            template += '                   option-label-prop="{{customFieldsDefinition.languageColumn}}" ';
            template += '                   option-value-prop="id" ';
            template += '                   options="tramCustomField.customField.listValues" ';
            template += '                   options-inline="false"></annexa-custom-field-component>';
            template += '           </div>';
            template += '         </div>';
            template += '       </div>';


            /*TODO PROVES AMB DESPLEGABLE*/
            template += '   <div class="col-sm-12 col-xs-12 bg-faded b-2x b-all  p-y-sm m-b-xs">';
            template += '       <div class="row" >';
            template += '           <div class="col-xs-4 col-sm-4 " >';
            template += '               <i class=" p-t-xs m-r-sm fa fa-bars grey-pencil" aria-hidden="true"></i>';
            template += '               <label class="label-strong   m-b-0">Aspecte</label>';
            template += '           </div>';
            template += '           <div class="col-xs-7 col-sm-7 ">';
            template += '               <p class="m-b-0  " >Nom del grup</p>';
            template += '           </div>';
            template += '           <div class="col-xs-1 col-sm-1" data-toggle="collapse" data-target="#collapseBlock" role="button" aria-expanded="false" aria-controls="collapseBlock">';
            template += '               <span class="fa fa-sort-down grey-pencil" aria-hidden="true"></span>';
            template += '           </div>';
            template += '       </div>'; /* /row collapse toggle*/

            template += '       <fieldset class="collapse  b-a-0 p-a-0"  id="collapseBlock">';
            template += '           <div class=" row m-t-sm">';
            template += '               <div class="col-xs-5 col-lg-4 p-r-0   p-t-sm">';
            template += '                   <label class="label-strong p-y-sm m-b-xs">Definició propietat</label>';
            template += '               </div>';
            template += '               <div class="col-xs-5 col-lg-5 col-xl-6  p-t-sm">';
            template += '                   <select class="form-control"><option value="Opcional">Opcional</option><option value="Requerit">Requerit</option><option value="No editable">No editable</option></select>';
            template += '               </div>';
            template += '               <div class="col-xs-2 col-lg-3 col-xl-2  p-t-sm">';
            template += '                   <p class="m-b-0 pull-right p-r-xs m-t-sm">';
            template += '                       <a href="" class="remove-button "><i class="fa fa-fw fa-pencil grey-pencil" title="{{\'global.literals.edit\' | translate}}"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span></a>';
            template += '                       <a href="" class="remove-button m-t-xs"><i class="fa fa-fw fa-remove grey-pencil" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></a>';
            template += '                   </p>';
            template += '               </div>';
            template += '           </div>';
            template += '           <div class="row p-t-xs">';
            template += '               <div class="col-xs-4">';
            template += '                   <label class="label-strong small p-y-sm m-b-xs">Valor per defecte</label>'
            template += '               </div>';
            template += '               <div class="col-xs-8 flexboxData outLabel">';
            template += '                   <input type="text" class="form-control" />';
            template += '               </div>';
            template += '           </div>';
            template += '           <div class=" row m-t-sm">';
            template += '               <div class="col-xs-5 col-lg-4 p-r-0   p-t-sm">';
            template += '                   <label class="label-strong p-y-sm m-b-xs">Definició propietat</label>';
            template += '               </div>';
            template += '               <div class="col-xs-5 col-lg-5 col-xl-6  p-t-sm">';
            template += '                   <select class="form-control"><option value="Opcional">Opcional</option><option value="Requerit">Requerit</option><option value="No editable">No editable</option></select>';
            template += '               </div>';
            template += '               <div class="col-xs-2 col-lg-3 col-xl-2  p-t-sm">';
            template += '                   <p class="m-b-0 pull-right p-r-xs m-t-sm">';
            template += '                       <a href="" class="remove-button "><i class="fa fa-fw fa-pencil grey-pencil" title="{{\'global.literals.edit\' | translate}}"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span></a>';
            template += '                       <a href="" class="remove-button m-t-xs"><i class="fa fa-fw fa-remove grey-pencil" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></a>';
            template += '                   </p>';
            template += '               </div>';
            template += '           </div>';
            template += '           <div class="row p-t-xs">';
            template += '               <div class="col-xs-4">';
            template += '                   <label class="label-strong small p-y-sm m-b-xs">Valor per defecte</label>'
            template += '               </div>';
            template += '               <div class="col-xs-8 flexboxData outLabel">';
            template += '                   <input type="text" class="form-control" />';
            template += '               </div>';
            template += '           </div>';
            template += '       </fieldset>';
            template += '   </div>';


            template += '   </div>';

            template += '   <div class="clearfix m-x padding">';
            template += '       <div class="col-xs-12 p-t-sm b-t">';
            template += '           <span class="m-b-xs small label-strong" translate="global.literals.addField">Add field</span>';
            template += '       </div>';
            template += '       <div class="col-xs-12 ">';
            template += '           <ui-select ng-model="newCustomField" theme="bootstrap" on-select="customFieldsDefinition.createModalFunction($model)">';
            template += '               <ui-select-match >{{$select.selected[customFieldsDefinition.languageColumn]}}</ui-select-match>';
            template += '               <ui-select-choices repeat="item in customFieldsDefinition.customFields | filter: $select.search" >';
            template += '                   <div ng-bind-html="item[customFieldsDefinition.languageColumn]"></div>';
            template += '               </ui-select-choices>';
            template += '           </ui-select>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope:{
                customFieldsDefinition: '='
            },
            transclude: true,
            link: function(scope, element, attr, ctrl, transclude) {
                scope.disableElement = function(cField){
                    if(!cField.disableElement || cField.frontendType == 'SELECT' || cField.frontendType == 'MULTIPLESELECT' || cField.frontendType == 'CHECKBOX' || cField.frontendType == 'RADIO'){
                        return false;
                    }  else{
                        return true;
                    }
                };

                scope.$on('annexaCustomFieldComponentModelChanged', function (event, args) {
                    var indexCustomField = $linq(scope.customFieldsDefinition.customFieldsProcedure).indexOf("x => x.customField.id == " + args.key);

                    if(indexCustomField != -1) {
                        if(args.model && args.model.hasOwnProperty('id')) {
                            scope.customFieldsDefinition.customFieldsProcedure[indexCustomField].value = args.model.id;
                        } else if(args.model) {
                            scope.customFieldsDefinition.customFieldsProcedure[indexCustomField].value = args.model;
                        }

                        if(args.model) {
                            scope.customFieldsDefinition.updateFunction(scope.customFieldsDefinition.customFieldsProcedure[indexCustomField]);
                        }
                    }
                });

                element.html(getTemplate(scope.customFieldsDefinition)).show();
                $compile(element.contents())(scope);
            }
        }

    }])
    .directive('annexaEditCustomField',['$compile', '$filter', 'Language','$rootScope', function ($compile, $filter, Language, $rootScope) {
        var getTemplate = function(scope, htmlPermissions) {
            var template = '';

            template += '<div class=" b-a-0 m-b-sm" ng-repeat="field in customFields | orderBy: \'viewOrder\'">';
            template += '   <div class="">';
            template += '       <label class="m-b-0 small">{{field.customField[languageColumn] || \'\'}} <span ng-if="field.required" class="text-danger" title="{{ \'global.INVALID_REQUIRED\' | translate}}">*</span><span ng-if="field.requiredForEndDossier" class="text-danger" title="{{ \'global.literals.REQUIRED_TO_END\' | translate}}">**</span><span ng-if="field.noEditable" class="text-danger" title="{{ \'global.literals.NO_EDITABLE\' | translate}}">***</span></label>';
            template += '   </div>';
            template += '   <div class="pos-relative">';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'INPUT\' && field.customField.backendType == \'DATETIME\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{\'editable-click\' : isUpdate}" ng-show="!inputDate1Form.$visible" editable-date="field.valueFromJSON" e-form="inputDate1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" ng-click="inputDate1Form.$show()" class="abs-r-0 abs-b-0 abs-b-0" ng-show="!inputDate1Form.$visible && isUpdate  && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs " ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'INPUT\' && field.customField.backendType == \'INTEGER\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{\'editable-click\' : isUpdate}" ng-show="!input1Form.$visible" editable-number="field.valueFromJSON" e-form="input1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0"  ng-click="input1Form.$show()" ng-show="!input1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'INPUT\' && field.customField.backendType == \'DECIMAL\'">';
            template += '           <a class="annexa-field p-l-xs "  ng-class="{\'editable-click\' : isUpdate}" ng-show="!input1Form.$visible" editable-number="field.valueFromJSON" e-step="{{field.step}}" e-form="input1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="input1Form.$show()" ng-show="!input1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'INPUT\'  && field.customField.backendType != \'DATETIME\' && field.customField.backendType != \'INTEGER\' && field.customField.backendType != \'DECIMAL\'">';
            template += '           <a class="annexa-field p-l-xs "  ng-class="{\'editable-click\' : isUpdate}" ng-click="openWindow(input1Form, $data, isUpdate, field.noEditable)" ng-show="!input1Form.$visible" editable-text="field.valueFromJSON" e-form="input1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required" e-maxlength="900">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="input1Form.$show()" ng-show="!input1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs minH-20 pre-line" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'TEXTAREA\'" >';
            template += '           <a class="annexa-field p-l-xs " ng-class="{\'editable-click\' : isUpdate}" ng-click="openWindow(textArea1Form, $data, isUpdate, field.noEditable)" ng-show="!textArea1Form.$visible" editable-textarea="field.valueFromJSON" e-maxlength="900" e-rows="3" e-form="textArea1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required">';
            template += '               {{ field.valueFromJSON }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="textArea1Form.$show()" ng-show="!textArea1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs " ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'SELECT\' || field.customField.frontendType == \'SELECT_LINKED\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!select1Form.$visible" editable-select="field.valueFromJSON" e-ng-options="item.value as item[languageColumn] for item in getOptions(field)" e-form="select1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required">';
            template += '               {{ getCustomFieldValue(field) }} ';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="select1Form.$show()" ng-show="!select1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'MULTIPLESELECT\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!multiselect1Form.$visible" editable-select="field.valueFromJSON" e-multiple e-ng-options="item.value as item[languageColumn] for item in field.customField.listValues"  e-form="multiselect1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="multiselect1Form.$show()" ng-show="!multiselect1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil "  title="{{\'global.literals.edit\' | translate}}"  aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="radio-list label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'CHECKBOX\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!checkBox1Form.$visible" editable-select="field.valueFromJSON" e-multiple e-ng-options="item.value as item[languageColumn] for item in field.customField.listValues" e-form="checkBox1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required">';
            template += '               {{ getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="checkBox1Form.$show()" ng-show="!checkBox1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '       <p class="radio-list label-strong m-b-xs" ng-class="{\'b-b min-h-21p\' : !isUpdate}" ng-if="field.customField.frontendType == \'RADIO\'">';
            template += '           <a class="annexa-field p-l-xs " ng-class="{ \'editable-click\' : isUpdate }" ng-show="!radio1Form.$visible"  editable-radiolist="field.valueFromJSON" e-ng-options="item.value as item[languageColumn] for item in field.customField.listValues" e-form="radio1Form" onbeforesave="updateCustomField(field,$data)" e-ng-required="field.required">';
            template += '               {{getCustomFieldValue(field) }}';
            template += '           </a>';
            template += '           <a href="" class="abs-r-0 abs-b-0" ng-click="radio1Form.$show()" ng-show="!radio1Form.$visible && isUpdate && !field.noEditable" role="button" aria-label="{{\'global.literals.edit\' | translate}}"' + htmlPermissions + '>';
            template += '               <i class="fa fa-pencil-square grey-pencil " title="{{\'global.literals.edit\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.edit\' | translate}}</span>';
            template += '           </a>';
            template += '       </p>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrinct: 'E',
            replace: true,
            scope: {
                customFields: '=',
                languageColumn: '=',
                isUpdate: '=',
                updateFunction: '=',
                permissions: '=',
                profile: '='
            },
            link: function(scope, element, attr) {
                scope.htmlPermissions = '';

                if(scope.permissions && scope.profile && scope.profile.length > 0) {
                    scope.htmlPermissions += ' annexa-permission annexa-permission-only="permissions" annexa-permission-only-profile="profile" ';
                } else if (scope.permissions) {
                	scope.htmlPermissions += ' annexa-permission annexa-permission-only="permissions"';
                } else if (scope.profile && scope.profile.length > 0) {
                	scope.htmlPermissions += ' annexa-permission annexa-permission-only-profile="profile" ';
                }

                angular.forEach(scope.customFields,function (value, key) {
                    if(value.value){
                        var object = angular.fromJson(value.value.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
                        if(object.value && object.value.replace) {
                            value.valueFromJSON = object.value.replace(/<br\s*\/?>/gi, '\n');
                        } else {
                            value.valueFromJSON = object.value;
                        }
                        if(value.customField.backendType == "DATETIME"){
                            value.valueFromJSON = new Date(value.valueFromJSON);
                        }
                    }else{
                        if(value.customField.frontendType == 'MULTIPLESELECT' || value.customField.frontendType == 'CHECKBOX'){
                            value.valueFromJSON = [];
                        }else{
                            value.valueFromJSON = '';
                        }
                    }
                    if (value && value.customField && value.customField.backendType == "DECIMAL"){
                        if(value.customField.decimalPositions) {
                            value.step = '0.' + Array(value.customField.decimalPositions).join('0') + '1';
                        }else{
                            value.step = '0.01'
                        }
                    }
                });

                scope.getCustomFieldValue = function(field){
                    var valueLiteral = '';
                    if(field.customField.frontendType == 'MULTIPLESELECT' || field.customField.frontendType == 'CHECKBOX'){
                        angular.forEach(field.valueFromJSON,function (valueJ, keyJ) {
                            angular.forEach(field.customField.listValues, function (value, key) {
                                if (value.value == valueJ) {
                                    if(valueLiteral) {
                                        valueLiteral = valueLiteral +', '+ value[scope.languageColumn];
                                    }else{
                                        valueLiteral = value[scope.languageColumn];
                                    }
                                }
                            });
                        });
                    }else if(field.customField.frontendType == 'SELECT' || field.customField.frontendType == 'SELECT_LINKED' || field.customField.frontendType == 'RADIO'){
                        angular.forEach(field.customField.listValues, function (value, key) {
                            if (value.value == field.valueFromJSON) {
                                valueLiteral = value[scope.languageColumn];
                            }
                        });
                    }else if(field.customField.frontendType == 'INPUT'){
                        if(field.customField.backendType == "DATETIME") {
                            valueLiteral = $filter('date')(field.valueFromJSON, 'dd/MM/yyyy');
                        }else if(field.customField.backendType == "DECIMAL"){
                            if(field.valueFromJSON){
                                valueLiteral = field.valueFromJSON;
                                var valueLiteral_parts = (""+valueLiteral).split('.');
                                if(valueLiteral_parts && valueLiteral_parts.length > 0){
                                    if(valueLiteral_parts[1]){
                                        if(field.customField && field.customField.decimalPositions){
                                            valueLiteral =  parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0."+valueLiteral_parts[1]).toFixed(field.customField.decimalPositions).replace('0.',',');
                                        }else{
                                            valueLiteral =  parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0."+valueLiteral_parts[1]).toFixed(2).replace('0.',',');
                                        }
                                    }else{
                                        if(field.customField && field.customField.decimalPositions){
                                            valueLiteral =  parseFloat(valueLiteral_parts[0]).toLocaleString() +  parseFloat("0.00").toFixed(field.customField.decimalPositions).replace('0.',',');
                                        }else {
                                            valueLiteral =  parseFloat(valueLiteral_parts[0]).toLocaleString() + ",00";
                                        }
                                    }
                                }
                            }else{
                                valueLiteral = field.valueFromJSON;
                            }
                        }else if(field.customField.backendType == "INTEGER"){
                            if(field.valueFromJSON){
                                valueLiteral = field.valueFromJSON.toLocaleString();
                            }else{
                                valueLiteral = field.valueFromJSON;
                            }
                        }else{
                            valueLiteral = field.valueFromJSON;
                        }
                    }
                    return valueLiteral;
                };

                scope.updateCustomField = function(field, data){
                    if(!field.required || (field.required && data)) {
                        var fieldValue = undefined;
                        if(field.value){
                            fieldValue = angular.fromJson(field.value.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
                        }
                        if(fieldValue == null || fieldValue == undefined){
                            fieldValue = {id:'cf_'+field.customField.id,value:'' };
                        }
                        if(data && data instanceof Date) {
                            fieldValue.value = new Date(Date.UTC(data.getFullYear(),data.getMonth(),data.getDate(),00,00,00));
                        }else{
                            fieldValue.value = data;
                        }
                        field.value = angular.toJson(fieldValue);
                        if(scope.updateFunction){
                            scope.updateFunction(field);
                        }

                        if(field.customField.frontendType == 'SELECT') {
                            scope.$broadcast('customFieldSelectSelected', { customField: field.customField.id, selectedValue: field.id });
                        }
                    }else{
                        return 'required';
                    }
                };

                scope.getOptions = function(field) {
                    if(field.customField.frontendType == 'SELECT') {
                        return field.customField.listValues;
                    } else if(field.customField.frontendType == 'SELECT_LINKED') {
                        var options = [];

                        var parentField = $linq(scope.customFields).singleOrDefault(undefined, "x => x.customField.id == " + field.customField.linkedCustomField.id);

                        if(parentField) {
                            var parentValue = $linq(parentField.customField.listValues).singleOrDefault(undefined, "x => x.value == " + parentField.valueFromJSON);

                            if(parentValue) {
                                options = $linq(field.customField.listValues).where("x => x.parentValue.id == " + parentValue.id).toArray();
                            }
                        }

                        return options;
                    }
                }

                scope.$on('customFieldSelectSelected', function (event, args) {
                    if(args.customField && args.selectedValue) {
                        var linkedFields = $linq(scope.customFields).where("x => x.customField.linkedCustomField && x.customField.linkedCustomField.id == " + args.customField).toArray();

                        _.forEach(linkedFields, function(field) {
                            scope.updateCustomField(field, '');
                            field.valueFromJSON = '';
                        });
                    }
                });
                
                scope.openWindow = function(element, modelValue, isUpdate, noEditable){
                	if(scope.canOpenWindow(modelValue)){
                		var modelValueString = ((modelValue && scope.languageColumn && modelValue[scope.languageColumn])?modelValue[scope.languageColumn]:modelValue);
                		if(modelValueString){
                			window.open(modelValueString);
                		}else if(isUpdate && !noEditable && !element.$visible){
                			element.$show();
                		}
                	}else if(isUpdate && !noEditable && !element.$visible){
                		element.$show();
                	}
                }
                
                scope.canOpenWindow = function(modelValue){
            		var exist = false;
                	if(modelValue){
                		var startUrls = ['http://','https://'];
                		if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.url_editable_inputs && $rootScope.app.configuration.url_editable_inputs.urls && $rootScope.app.configuration.url_editable_inputs.urls.length > 0){
                			_.forEach($rootScope.app.configuration.url_editable_inputs.urls, function(item){
                				startUrls.push(item);
                			});
                		}
                		var modelValueString = ((modelValue && scope.languageColumn && modelValue[scope.languageColumn])?modelValue[scope.languageColumn]:modelValue);
                		if(modelValueString){
	                		_.forEach(startUrls, function(item){
	                			if(modelValueString.startsWith(item.toLowerCase())){
	                				exist = true;
	                			}
	                		});
                		}
                	}
                	return exist;
                }
                element.html(getTemplate(scope.customFieldsDefinition, scope.htmlPermissions)).show();
                $compile(element.contents())(scope);
            }
        }
    }]);