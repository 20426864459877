
/**
 * Created by osirvent on 15/03/2016.
 */
var TableData = function(origin, objectType, url, dom, sortName, sort, lengthChange, processing, serverSide, filter, responsive,jsonDepth) {
    this.url = url;
    this.processing = processing == undefined ? true : processing;
    this.serverSide = serverSide == undefined ? true : serverSide;
    this.lengthChange = lengthChange == undefined ? true : lengthChange;
    this.filter = filter == undefined ? false : filter;
    this.sort = sort == undefined ? [[0,'asc']] : sort;
    this.sortName = sortName == undefined ? '' : sortName;
    this.dom = dom == undefined ? 'rt<"row"<"col-md-6"<"row"<"col-md-6"l><"col-md-6"i>>><"col-md-6"p>>' : dom;
    this.origin = origin;
    this.objectType = objectType;
    this.responsive = responsive == undefined ? true : responsive;
    this.jsonDepth = jsonDepth == undefined ? 3 : jsonDepth;

    this.setProcessing = function (processing) {
        this.processing = processing;
        return this;
    }

    this.setServerSide = function (serverSide) {
        this.serverSide = serverSide;
        return this;
    }

    this.setLengthChange = function (lengthChange) {
        this.lengthChange = lengthChange;
        return this;
    }
    
    this.setFilter = function (filter) {
        this.filter = filter;
        return this;
    }
    
    this.setSort = function (sort) {
        this.sort = sort;
        return this;
    }
    
    this.setSortName = function (sortName) {
        this.sortName = sortName;
        return this;
    }
    
    this.setDom = function (dom) {
        this.dom = dom;
        return this;
    }
    
    this.setResponsive = function (responsive) {
        this.responsive = responsive;
        return this;
    }
    
    this.setJsonDepth = function (jsonDepth) {
        this.jsonDepth = jsonDepth;
        return this;
    }
};

var StrippedColumn = function(filter, title, lengthString) {
    this.filter = filter;
    this.title = title;
    this.getTitle = function() {
        return filter('translate')(title);
    }
    var lengthTotal = 30;
    if(lengthString){
        lengthTotal = lengthString;
    }
    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data){
            content += '<span class="hellip--medium" title="'+data+'">';
            content += data;
            content += '</span>';
        }
        return content;
    }
};

var EllipsableColumn = function (filter, title, length) {
    this.filter = filter;
    this.title = title;
    this.length = length ? length : 30;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(data){
            if(data.length > length) {
                content = data.substr(0,30) + '...';
            }else{
                content = data;
            }
        }

        return content;
    }
}

var PartialLanguageColumn = function(filter, title, partialLanguage) {

    this.filter = filter;
    this.title = title;
    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        return filter('translate')(partialLanguage+data);
    }
};

var DateColumn = function(filter, title) {
    this.filter = filter;
    this.title = title;
    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data){
            content =  filter('date')(new Date(data), 'dd/MM/yyyy');
        }
        return content;
    }
};

var DateTimeColumn = function(filter, title) {
    this.filter = filter;
    this.title = title;
    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data){
            content =  filter('date')(new Date(data), 'dd/MM/yyyy - HH:mm');
        }
        return content;
    }
}

var ActiveByDateColumn = function (scope, filterModel, fieldName, options, table) {
    var name = 'filterColumn' + fieldName;
    scope[name] = { options: options, dataTable: table };

    this.getTitle = function() {
        return '<iconsearchcolumn definition="' + name + '" filterModel="' + filterModel + '" fieldName="' + fieldName +'"/>';
    }


    this.getRender = function (data, type, full, meta) {
        var content = '';

        if(data == null || new Date() < new Date(data)) {
            content += '<i class="m-l-xs fa fa-circle text-success" ></i>';
        } else {
            content += '<i class="m-l-xs fa fa-circle text-danger" ></i>';
        }

        return content;
    }
}

var AngularTranslateColumn = function(filter, title, prefix, upper) {
    this.filter = filter;
    this.title = title;
    this.prefix = prefix;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(prefix) {
            content += prefix;
        }

        content += ((data && data.id) ? ((upper) ? data.id.toUpperCase() : data.id) : ((upper) ? data.toUpperCase() : data));
        
        return filter('translate')(content);
    }
}

var DatabaseTranslatedColumn = function(filter, title, language) {
    this.filter = filter;
    this.title = title;
    this.language = language;

    this.getColumn = function() {
        return language;
    }

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data){
            content =  data;
        }
        return content;
    }
}

var DatabaseTranslatedListColumn = function(filter, title, language, field) {
    this.filter = filter;
    this.title = title;
    this.language = language;

    this.getColumn = function() {
        return field;
    }

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data && data.length > 0){
            _.forEach(data, function(value, key){
                if(key > 0){
                    content +=  ' | '+ value[language];
                }else{
                    content +=  value[language];
                }
            });
        }
        return content;
    }
}

var DatabaseTranslatedParentColumn = function(filter, title, language, field) {
    this.filter = filter;
    this.title = title;
    this.language = language;

    this.getColumn = function() {
        return field;
    }

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data && data[language]){
            content =  data[language];
        }
        return content;
    }
}

var DatabaseTranslatedColumn2 = function(filter, title, language) {
    this.filter = filter;
    this.title = title;
    this.language = language;

    this.getColumn = function() {
        return language;
    }

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data){
            content = filter('annexatranslate')(data, language);
        }
        return content;
    }
}

var ActionsColumn = function(title, firstButton, dropdownButtons, data2, target) {
    this.title = title;
    this.firstButton = firstButton;
    this.dropdownButtons = dropdownButtons;
    this.getTitle = function () {
        var content = '<div class="column-actions wi-100 text-center">' + title + '</div>';

        return content;
    }
    this.data2 = data2;
    this.getRender = function(data, type, full, meta) {
        var content = '';
        var content3 = '<div class="btn-group dropdown flexbuttons ">';
        var replaceData = firstButton.click.replace('[data]', data);
        if(data2 && full[data2]){
            replaceData = replaceData.replace('[data2]', full[data2]);
        }
        if(firstButton && firstButton.addProperties){
        	_.forEach(firstButton.addProperties, function(prop){
        		if(prop && full[prop] != null && full[prop] != undefined){
        			replaceData = replaceData.replace('['+prop+']', full[prop]);
        		}else if(prop.includes('.')){
        			var props = prop.split('.');
        			if(props && props.length > 0){
        				var obj = full;
        				_.forEach(props, function(propSplit){
        					if(obj && propSplit && obj[propSplit] != null && obj[propSplit] != undefined){
        						obj = obj[propSplit];
        					}else{
        						obj = undefined;
        					} 
        				});
        				if(obj != undefined && obj != null){
        					replaceData = replaceData.replace('['+prop+']', obj);
        				}else{
        					replaceData = replaceData.replace('['+prop+']', undefined);
        				}
        			}else{
        				replaceData = replaceData.replace('['+prop+']', undefined);
        			}
        		}else{
        			replaceData = replaceData.replace('['+prop+']', undefined);
        		}
        		
        	});
        }
        if(firstButton) {
            if(target){
                content += '    <a class="btn primary" ';
            }else{
                content += '    <button class="btn primary center-block" ';
            }
            if(firstButton.href) {
                content += 'ui-sref = "' + replaceData + '"';
            } else {
                content += 'ng-click = "' + replaceData + '"';
            }
            if(firstButton.permissions && firstButton.permissions.length > 0) {
                content += ' permission permission-only="[';
                var first = true;
                angular.forEach(firstButton.permissions, function (permission) {
                    if(!first) {
                        content += ',';
                    } else {
                        first = false;
                    }

                    content += "'" + permission + "'";
                })
                content += ']" permission-on-authorized="permEnableElement" permission-on-unauthorized="permDisableElement"'
            }
            if(target){
                content += ' target="'+target+'" ';
            }
            content += '>';
            if (firstButton.icon) {
                content += '<i class="fa ' + firstButton.icon + '" aria-hidden="true"></i>';
            }
            content += '        <span translate="' + firstButton.label + '">' + firstButton.label + '</span>';
            if(target){
                content += '    </a>';
            }else{
                content += '    </button>';
            }

            if(dropdownButtons && dropdownButtons.length > 0) {
                var content2 = '';
                angular.forEach(dropdownButtons, function (value, key) {
                    var addButton = true;

                    if(value.showFunction) {
                        addButton = value.showFunction(data, type, full, meta);
                    }

                    if(addButton) {
                        var replaceDropDownData = value.click.replace('[data]', data);
                        if (data2 && full[data2]) {
                            replaceDropDownData = replaceDropDownData.replace('[data2]', full[data2]);
                        }
                        if(value && value.addProperties){
                        	_.forEach(value.addProperties, function(prop){
                        		if(prop && full[prop] != null && full[prop] != undefined){
                        			replaceDropDownData = replaceDropDownData.replace('['+prop+']', full[prop]);
	                    		}else if(prop.includes('.')){
	                    			var props = prop.split('.');
	                    			if(props && props.length > 0){
	                    				var obj = full;
	                    				_.forEach(props, function(propSplit){
	                    					if(obj && propSplit && obj[propSplit] != null && obj[propSplit] != undefined){
	                    						obj = obj[propSplit];
	                    					}else{
	                    						obj = undefined;
	                    					} 
	                    				});
	                    				if(obj != undefined && obj != null){
	                    					replaceDropDownData = replaceDropDownData.replace('['+prop+']', obj);
	                    				}else{
	                    					replaceDropDownData = replaceDropDownData.replace('['+prop+']', undefined);
	                    				}
	                    			}else{
	                    				replaceDropDownData = replaceDropDownData.replace('['+prop+']', undefined);
	                    			}
	                    		}else{
	                    			replaceDropDownData = replaceDropDownData.replace('['+prop+']', undefined);
	                    		}
                    		});
                        }
                        if (value.href) {
                            content2 += '<a class="dropdown-item" ui-sref ="' + replaceDropDownData + '"';
                        } else {
                            content2 += '<a class="dropdown-item" ng-click="' + replaceDropDownData + '"';
                        }
                        if(value.permissions && value.permissions.length > 0) {
                            content2 += ' permission permission-only="[';
                            var first = true;
                            angular.forEach(value.permissions, function (permission) {
                                if(!first) {
                                    content2 += ',';
                                } else {
                                    first = false;
                                }

                                content2 += "'" + permission + "'";
                            })
                            content2 += ']"  permission-on-authorized="permEnableElement" permission-on-unauthorized="permDisableElement"'
                        }
                        content2 += '>';
                        if (value.icon) {
                            content2 += '<i class="fa ' + value.icon + '"></i>';
                        }
                        content2 += '    <span translate="' + value.label + '">' + value.label + '</span>';
                        content2 += '</a>';
                    }
                });
                if(content2){
                    content = content3 + content;
                    content += '  <div class="btn-group">';
                    content += '    <button class="btn primary dropdown-toggle p-x-sm" data-toggle="dropdown" title="{{ \'global.literals.showOptions\' | translate }}"><span class="sr-only">{{ \'global.literals.showOptions\' | translate }}</span></button>';
                    content += '    <div class="dropdown-menu primary pull-right">';
                    content += content2;
                    content += '    </div>';
                    content += '  </div>';
                }
                else {
                    content = '<div class="btn-group flexbuttons">' + content;
                }
            }

            content += '</div>';
        }

        return content;
    }
}

var ActionsColumnAttendees = function(title, firstButton, dropdownButtons, data2, target) {
    this.title = title;
    this.firstButton = firstButton;
    this.dropdownButtons = dropdownButtons;
    this.getTitle = function () {
        var content = '<div class="column-actions wi-100 text-center">' + title + '</div>';

        return content;
    }
    this.data2 = data2;
    this.getRender = function(data, type, full, meta) {
        var content = '';
        var content3 = '<div class="btn-group dropdown flexbuttons ">';
        var replaceData = firstButton.click.replace('[data]', data);
        if(data2 && full[data2]){
            replaceData = replaceData.replace('[data2]', full[data2]);
        }
        if(firstButton) {
            if(target){
                content += '    <a class="btn primary" ';
            }else{
                content += '    <button class="btn primary center-block" ';
            }
            if(firstButton.href) {
                content += 'ui-sref = "' + replaceData + '"';
            } else {
                content += 'ng-click = "' + replaceData + '"';
            }
            if(firstButton.permissions && firstButton.permissions.length > 0) {
                content += ' permission permission-only="[';
                var first = true;
                angular.forEach(firstButton.permissions, function (permission) {
                    if(!first) {
                        content += ',';
                    } else {
                        first = false;
                    }

                    content += "'" + permission + "'";
                })
                content += ']" permission-on-authorized="permEnableElement" permission-on-unauthorized="permDisableElement"'
            }
            if(target){
                content += ' target="'+target+'" ';
            }
            content += '>';
            if (firstButton.icon) {
                content += '<i class="fa ' + firstButton.icon + '" aria-hidden="true"></i>';
            }
            content += '        <span translate="' + firstButton.label + '">' + firstButton.label + '</span>';
            if(target){
                content += '    </a>';
            }else{
                content += '    </button>';
            }

            if(dropdownButtons && dropdownButtons.length > 0 && (!full.member || !full.member.id)) {
                var content2 = '';
                angular.forEach(dropdownButtons, function (value, key) {
                    var addButton = true;

                    if(value.showFunction) {
                        addButton = value.showFunction(data, type, full, meta);
                    }

                    if(addButton) {
                        var replaceDropDownData = value.click.replace('[data]', data);
                        if (data2 && full[data2]) {
                            replaceDropDownData = replaceDropDownData.replace('[data2]', full[data2]);
                        }
                        if (value.href) {
                            content2 += '<a class="dropdown-item" ui-sref ="' + replaceDropDownData + '"';
                        } else {
                            content2 += '<a class="dropdown-item" ng-click="' + replaceDropDownData + '"';
                        }
                        if(value.permissions && value.permissions.length > 0) {
                            content2 += ' permission permission-only="[';
                            var first = true;
                            angular.forEach(value.permissions, function (permission) {
                                if(!first) {
                                    content2 += ',';
                                } else {
                                    first = false;
                                }

                                content2 += "'" + permission + "'";
                            })
                            content2 += ']"  permission-on-authorized="permEnableElement" permission-on-unauthorized="permDisableElement"'
                        }
                        content2 += '>';
                        if (value.icon) {
                            content2 += '<i class="fa ' + value.icon + '"></i>';
                        }
                        content2 += '    <span translate="' + value.label + '">' + value.label + '</span>';
                        content2 += '</a>';
                    }
                });
                if(content2){
                    content = content3 + content;
                    content += '  <div class="btn-group">';
                    content += '    <button class="btn primary dropdown-toggle p-x-sm" data-toggle="dropdown" title="{{ \'global.literals.showOptions\' | translate }}"><span class="sr-only">{{ \'global.literals.showOptions\' | translate }}</span></button>';
                    content += '    <div class="dropdown-menu primary pull-right">';
                    content += content2;
                    content += '    </div>';
                    content += '  </div>';
                }
                else {
                    content = '<div class="btn-group flexbuttons">' + content;
                }
            }

            content += '</div>';
        }
        return content;
    }
}


var ActionButton = function(label, click, icon, href, showFunction, permissions, addProperties) {
    this.label = label || '';
    this.click = click;
    this.icon = icon;
    this.href = href == undefined ? false : href;
    this.showFunction = showFunction;
    this.addProperties = addProperties;
    
    if(permissions) {
        if(typeof permissions === 'string') {
            this.permissions = [permissions];
        } else {
            this.permissions = permissions;
        }
    } else {
        this.permissions = [];
    }

    this.setPermissions = function (permissions) {
        if(permissions) {
            if(typeof permissions === 'string') {
                this.permissions = [permissions];
            } else {
                this.permissions = permissions;
            }
        } else {
            this.permissions = [];
        }

        return this;
    }
}

var IconSearchOption = function (key, value, buton_icon, dropdown_icon, label) {
    this.key = key;
    this.value = value;
    this.buton_icon = buton_icon;
    this.dropdown_icon = dropdown_icon;
    this.label = label;
};

var IconSearchRender = function (scope, defObjects) {
    this.getRender = function(data, type, full, meta) {
        if(scope && defObjects && scope[defObjects].options){
            for(var i=0; i<scope[defObjects].options.length; i++){
                var object = scope[defObjects].options[i];
                var compareValue = data;

                if(scope[defObjects].renderValueFunction) {
                    compareValue = scope[defObjects].renderValueFunction(data);
                }

                if (object.key == compareValue) {
                    if(scope[defObjects].rowFunction && full.id) {
                        var button = new annexaButton('md-btn p-a-0 md-flat md-flat-flat',scope[defObjects].rowFunction + '(' + full.id + ')',undefined,undefined,object.buton_icon);
                        return button.htmlObj.getHtml();
                    } else {
                        var icon = new annexaIcon(object.buton_icon, undefined, undefined, undefined);
                        return icon.htmlObj.getHtml();
                    }
                }
            }

        }
        return "";
    };
}

var IconSearchColumn = function (name, title, width, render, sortable, showAll, options, dataTable, renderValueFunction, rowFunction) {
    this.name = name;
    this.title = title;
    this.width = width;
    this.render = render;
    this.sortable = sortable;
    this.showAll = showAll;
    this.dataTable = dataTable;
    this.options = options == undefined ? [] : options;
    if(this.showAll) {
        this.options.unshift(new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'));
    }
    this.renderValueFunction = renderValueFunction;
    this.rowFunction = rowFunction;
    this.getTitle = function(translate_filter, translate_part){
        var titleAux = '';
        if(title) {
            if(translate_filter) {
                titleAux = translate_filter(translate_part)(this.title);
            } else {
                titleAux =this.title;
            }
        }
        return titleAux;
    }
    this.getRender = function(){
        return render.getRender;
    }

    this.getDTColumnBuilder = function (builder,translate_filter, translate_part) {
        var column = builder.newColumn(this.name);

        if(width) {
            column.withOption('width', width);
        }

        if(title) {
            if(translate_filter) {
                column.withTitle(translate_filter(translate_part)(this.title));
            } else {
                column.withTitle(this.title);
            }
        }

        if(render) {
            column.renderWith(render.getRender);
        }

        if(!sortable) {
            column.notSortable();
        }

        return column;
    };

}

var IconFilterColumn = function (scope, filterModel, fieldName, options, table) {
    var name = 'filterColumn' + fieldName;
    scope[name] = { options: options, dataTable: table };

    this.getTitle = function() {
        return '<iconsearchcolumn definition="' + name + '" filterModel="' + filterModel + '" fieldName="' + fieldName +'"/>';
    }

    this.getRender = function(data, type, full, meta) {
        if(scope && scope[name].options){
            for(var i=0; i<scope[name].options.length; i++){
                var object = scope[name].options[i];
                var compareValue = data;

                if(scope[name].renderValueFunction) {
                    compareValue = scope[name].renderValueFunction(data);
                }

                if (object.key == compareValue) {
                    if(scope[name].rowFunction && full.id) {
                        var button = new annexaButton('md-btn p-a-0 md-flat',scope[defObjects].rowFunction + '(' + full.id + ')',undefined,undefined,object.buton_icon);
                        return button.htmlObj.getHtml();
                    } else {
                        var icon = new annexaIcon(object.buton_icon, undefined, undefined, undefined);
                        return icon.htmlObj.getHtml();
                    }
                }
            }

        }
        return "";
    }
}

var LinkColumn = function (filter, title, linkFunction, fullField) {
    this.filter = filter;
    this.title = title;
    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(data) {
            content += '<a class="text-primary" href="" ng-click="' + linkFunction + '(' + full[fullField] + ')"><span>' + data + '</span></a>';
        }

        return content;
    }
}

var UIRouterStateColumnDataLink = function(filter, title, state, evalFunction, blank) {
    this.filter = filter;
    this.title = title;

    this.getTitle = function () {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(data && evalFunction) {
            var stateAux = state.replace('[data]', eval(evalFunction));
            if(!blank) {
                content = '<a href ui-sref="' + stateAux + '" class="text-info linkNoStyle">' + data + '</a>';
            } else {
                content = '<a href target="_blank" ui-sref="' + stateAux + '" class="text-info linkNoStyle">' + data + '</a>';
            }
        }

        return content;
    }
}

var UIRouterStateColumn = function(filter, title, state, contentProperty, evalFunction, blank, addDialogProperties) {
    this.filter = filter;
    this.title = title;

    this.getTitle = function () {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data && addDialogProperties){
        	 content = '<a class="text-primary" href="" ng-click="'+state.replace('[data]', data)+'"><span>';
        	 if(data && contentProperty && full[contentProperty]) {
        		 content +=  + full[contentProperty];
 	         } else if(data && evalFunction) {
 	            var contentEval = eval(evalFunction);
 	            content += contentEval;
 	         }
        	 content += '</span></a>';
            _.forEach(addDialogProperties, function(prop){
        		if(prop && full[prop] != null && full[prop] != undefined){
        			content = content.replace('['+prop+']', full[prop]);
        		}else if(prop.includes('.')){
        			var props = prop.split('.');
        			if(props && props.length > 0){
        				var obj = full;
        				_.forEach(props, function(propSplit){
        					if(obj && propSplit && obj[propSplit] != null && obj[propSplit] != undefined){
        						obj = obj[propSplit];
        					}else{
        						obj = undefined;
        					} 
        				});
        				if(obj != undefined && obj != null){
        					content = content.replace('['+prop+']', obj);
        				}else{
        					content = content.replace('['+prop+']', undefined);
        				}
        			}else{
        				content = content.replace('['+prop+']', undefined);
        			}
        		}else{
        			content = content.replace('['+prop+']', undefined);
        		}
        		
        	});
        }else{
	        if(data && contentProperty && full[contentProperty]) {
	            var stateAux = state.replace('[data]', data);
	            stateAux = stateAux.replace('[full.id]', full.id);
	            if(!blank) {
	                content = '<a href ui-sref="' + stateAux + '" class="text-info linkNoStyle">' + full[contentProperty] + '</a>';
	            } else {
	                content = '<a href target="_blank" ui-sref="' + stateAux + '" class="text-info linkNoStyle">' + full[contentProperty] + '</a>';
	            }
	        } else if(data && evalFunction) {
	            var contentEval = eval(evalFunction);
	            var stateAux = state.replace('[data]', data);
	            stateAux = stateAux.replace('[full.id]', full.id);
	            if(full.notification != null && full.notification.dossier != null && full.notification.dossier.dossierTransactions != null){
	            	var dtId = 0;
	            	for(var i = 0; i < full.notification.dossier.dossierTransactions.length; i++){
	            		var dt = full.notification.dossier.dossierTransactions[i];
	            		if(dt.actual){
	            			dtId = dt.id;
	            		}
	            	}
	            }
	            stateAux = stateAux.replace('[data2]', dtId);	            
	            if(!blank) {
	                content = '<a href ui-sref="' + stateAux + '" class="text-info linkNoStyle">' + contentEval + '</a>';
	            } else {
	                content = '<a href target="_blank" ui-sref="' + stateAux + '" class="text-info linkNoStyle">' + contentEval + '</a>';
	            }
	        }
        }
        return content;
    }
}

var UIRouterStateColumnNoLink = function(filter, title, state, contentProperty, evalFunction) {
    this.filter = filter;
    this.title = title;

    this.getTitle = function () {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(data && contentProperty && full[contentProperty]) {

            content = '<p>' + full[contentProperty] + '</p>';
        }
        else if(data && evalFunction) {
            var contentEval = eval(evalFunction);

            content = '<p>' + contentEval + '</p>';
        }

        return content;
    }
}

var CustomFieldColumn = function (customColumn, langColumn) {
    this.customColumn = customColumn;
    this.langColumn = langColumn;

    this.getTitle = function () {
        return customColumn[langColumn];
    }

    this.getRender = function(data, type, full, meta) {
        var formatDate = function(stringDate) {
            var date = new Date(stringDate);

            var dd = date.getDate();
            var mm = date.getMonth()+1;

            var yyyy = date.getFullYear();

            if(dd<10){
                dd='0'+dd;
            }

            if(mm<10){
                mm='0'+mm;
            }

            return dd+'/'+mm+'/'+yyyy;
        }

        var content = '';

        var custValue = $linq(full.customFields).firstOrDefault(undefined, "x => x.customField.id == " + customColumn.id);

        if(custValue) {
            var value = angular.fromJson(custValue.value);

            if(value && value.value) {
                if(customColumn.frontendType == 'INPUT' || customColumn.frontendType == 'TEXTAREA') {
                    if(customColumn.backendType != 'DATETIME') {
                        content += value.value;
                    } else {
                        content += formatDate(value.value);
                    }
                } else if(customColumn.frontendType == 'SELECT' || customColumn.frontendType == 'SELECT_LINKED' || customColumn.frontendType == 'MULTIPLESELECT' || customColumn.frontendType == 'CHECKBOX' || customColumn.frontendType == 'RADIO') {
                    if(value && value.value){
                    	var canCallLinq = false;
	                	if(Array.isArray(value.value)){
	                		if(value.value.length > 0){
	                			canCallLinq = true;
	                		}
	                	}else{
	                		canCallLinq = true;
	                	}
	                	if(canCallLinq){
	                    	var listValue = $linq(customColumn.listValues).firstOrDefault(undefined, "x => x.value == " + value.value);
		
		                    if(listValue) {
		                        content += listValue[langColumn];
		                    }
	                	}
                    }
                }
            }
        }

        return content;
    }

}
var UserColumn = function(filter, title, dataInRow, noLink) {
    this.filter = filter;
    this.title = title;
    this.dataInRow = dataInRow || false;
    this.noLink = noLink || false;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        var identifier = '';
        var name = '';

        if(dataInRow) {
            identifier = full.identifier;
            name += full.name + ' ' + full.surename1;
            if (full.surename2) {
                full += ' ' + full.surename2;
            }
        } else if(data){
            identifier = data.identifier;
            name += data.name + ' ' + data.surename1;
            if (data.surename2) {
                name += ' ' + data.surename2;
            }
        }
        if(identifier && name) {
            if(noLink){
                content = name;
            }else{
                content = '<a ui-sref="annexa.profile({idUser: \'' + identifier + '\'})" class="text-primary tresPuntets" title="' + name + '">' + name + '</a>';
            }
        }
        return content;
    }
};

var UserEMailColumn = function (filter, title, dataInRow) {
    this.filter = filter;
    this.title = title;
    this.dataInRow = dataInRow || false;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        var tooltip = '';
        var model = data;

        if(dataInRow) {
            model = full;
        }

        if(model) {
            if (model.email) {
                tooltip = filter('translate')('global.profile.email') + ': ' + model.email;
            }

            if (tooltip == '') {
                content += '<i class="fa fa-envelope fa-lg opacity" aria-hidden="true"></i>';
            } else {
                content += '<i class="fa fa-envelope fa-lg" uib-tooltip="' + tooltip + '" tooltip-placement="top" aria-label="' + tooltip + '"></i>';
            }
        }else{
        	content += '<i class="fa fa-envelope fa-lg opacity" aria-hidden="true"></i>';
        }

        return content;
    }
}

var UserPhoneColumn = function (filter, title, dataInRow) {
    this.filter = filter;
    this.title = title;
    this.dataInRow = dataInRow || false;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        var tooltipPhoneLabel = '';
        var tooltipPhoneContent = '';
        var tooltip = '';
        var model = data;

        if(dataInRow) {
            model = full;
        }

        if(model) {
            if (model.phone) {
                tooltipPhoneLabel += filter('translate')('global.profile.phone');
                tooltipPhoneContent += model.phone;

                if (model.extension) {
                    tooltipPhoneLabel += ' (' + filter('translate')('global.literals.extension') + ')';
                    tooltipPhoneContent += ' (' + model.extension + ')';
                }

                tooltipPhoneLabel += ": ";
            } else {
                if (model.extension) {
                    tooltipPhoneLabel += filter('translate')('global.literals.extension') + ': ';
                    tooltipPhoneContent += model.extension;
                }
            }

            tooltip = tooltipPhoneLabel + tooltipPhoneContent;

            if (model.cellPhone) {
                if (tooltip != '') {
                    tooltip += ' / ';
                }

                tooltip += filter('translate')('global.literals.cellPhone') + ': ' + model.cellPhone;
            }

            if (tooltip == '') {
                content += '<i class="fa fa-mobile fa-2x opacity"></i>';
            } else {
                content += '<i class="fa fa-mobile fa-2x" uib-tooltip="' + tooltip + '" tooltip-placement="top" aria-label="' + tooltip + '"></i>';
            }
        }else{
            content += '<i class="fa fa-mobile fa-2x opacity"></i>';
        }

        return content;
    }
}

var BooleanColumn = function(filter, title, column) {
    this.getColumn = function() {
        return column;
    }

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data){
            content = '<i class="m-l-xs fa fa-check-square-o"></i>';
        }else{
            content = '<i class="m-l-xs text-grey fa fa-square-o"></i>';
        }
        return content;
    }
}

var RemaingDaysColumn = function(filter, title, helper) {
    this.filter = filter;
    this.title = title;
    this.helper = helper;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data) {
            content += '<span class="label '+helper.getWorkingDaysClass(data)+'">';
            if (data > 5) {
                content += '+';
            }
            content += data + "</span>";
        }
        return content;
    }
};

var StartDateColumn = function(filter, title) {
    this.filter = filter;
    this.title = title;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        content = filter('date')(new Date(data), 'dd/MM/yyyy');

        var name = full.dossier.creatorUser.name+' '+full.dossier.creatorUser.surename1 ;
        if(full.dossier.creatorUser.surename2){
            name = name  +' '+full.dossier.creatorUser.surename2 ;
        }
        content += '<br/><span>'+ filter('translate')('global.literals.created_for')+': </span><a href ui-sref="annexa.profile({idUser: \''+full.dossier.creatorUser.identifier+'\'})" class="text-info linkNoStyle tresPuntets" title="'+name+'">'+name+'</a>';

        return content;
    }
};

var RelatedDossiersColumn = function () {
    this.getTitle = function() {
        return '';
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(data.length != 0) {
            content = '<i class="fa fa-link" title="{{\'tram.literals.related_dossier\' | translate}}"><span class="label rounded label-sm amber font-default">' + data.length + '</span></i>';
        }
        return content;
    }
};

var ThirdColumn = function (filter, title, overFull) {
    this.filter = filter;
    this.title = title;
    this.overFull = overFull;

    this.getTitle = function () {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        var third = overFull ? full : data;

        if(third) {
            if(third.thirdType && third.thirdType != "PHISICAL"){
                content = '<span>' + third.corporateName + '</span>';
            }else{
                content = '<span>' + third.name +' '+ third.surename1 + ((third.surename2) ? ' '+ third.surename2 : '')+ '</span>';
            }

        }

        return content;
    }
}

var PrincipalThirdColumn = function(filter, title) {
    this.filter = filter;
    this.title = title;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data && data.length > 0) {
            angular.forEach(data, function(value, key) {
                if(value && value.principal){
                    if(value.third.thirdType && value.third.thirdType != "PHISICAL"){
                        content = '<span>' + value.third.corporateName + '</span>';
                    }else{
                        content = '<span>' + value.third.name +' '+ value.third.surename1 + ((value.third.surename2) ? ' '+value.third.surename2 : '')+ '</span>';
                    }
                }
            });
        }
        return content;
    }
};

var IconTooltipColumn = function (filter, title, icon, object) {
    this.getTitle = function() {
        if(title){
            return filter('translate')(title);
        }else{
            return '';
        }
    }
    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data) {
            var tooltip = '';
            if(data && Array.isArray(data)){
                angular.forEach(data, function(value, key){
                    if(value){
                        tooltip += value[object];
                    }
                });
            }else{
                tooltip = data;
            }
            content = '<i class="text-grey text-lg fa '+icon+'" title="'+tooltip+'"></i>';
        }else{
            content = '<i class="text-grey text-lg fa '+icon+'-o"></i>';
        }
        return content;
    }
}

var ListTranslatedColumn = function(filter, title, language) {
    this.filter = filter;
    this.title = title;
    this.language = language;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data && data.length){
            angular.forEach(data, function(value,key){
                content += value[language];
                if(key < data.length-1){
                    content += ', ';
                }
            });
        }
        return content;
    }
}

var ObjectTextColumn = function(filter, title, contentProperty) {
    this.filter = filter;
    this.title = title;
    this.contentProperty = contentProperty;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(data && data[contentProperty]) {
            content = data[contentProperty];
        }

        return content;
    }
}

var TextColumn = function(filter, title) {
    this.filter = filter;
    this.title = title;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(data) {
            content = data;
        }

        return content;
    }
}

var IconColumn = function (filter, title, contentProperty, titleProperty) {
    this.filter = filter;
    this.title = title;
    this.contentProperty = contentProperty;
    this.titleProperty = titleProperty;

    this.getTitle = function() {
        return filter('translate')(title);
    }

    this.getRender = function (data, type, full, meta) {
        var content = '';

        if(data && data[titleProperty] && data[contentProperty]) {
            if(data.style) {
                content = '<annexa-circle-icon bg-color="' + data.style + '" title="' + data[titleProperty] +'" content="' + data[contentProperty] + '"></annexa-circle-icon>';
            } else {
                content = '<icon name="' + data[contentProperty] + '" title="' + data[titleProperty] + '">';
            }
        }

        return content;
    }

}

var CommentColumn = function(filter, title, prefix) {
    this.filter = filter;
    this.title = title;
    this.prefix = prefix;

    this.getTitle = function () {
        return title ? filter('translate')(title) : '';
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(data) {
            content += '<i class="text-grey fa fa-fw fa-comments-o" title="' + (prefix ? prefix : '') + data + '" aria-hidden="true"></i><span class="sr-only">' + (prefix ? prefix : '') + data + '</span>';
        }

        return content;
    }
}

var CommentSecColumn = function(filter, title, prefix) {
    this.filter = filter;
    this.title = title;
    this.prefix = prefix;

    this.getTitle = function () {
        return title ? filter('translate')(title) : '';
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(data) {
            content += '<i class="text-grey fa fa-fw fa-comments" title="' + (prefix ? prefix : '') + data + '" aria-hidden="true"></i><span class="sr-only">' + (prefix ? prefix : '') + data + '</span>';
        }

        return content;
    }
}

var UrgentColumn = function(filter, title, reasonColumn) {
    this.filter = filter;
    this.title = title;
    this.reasonColumn = reasonColumn;

    this.getTitle = function () {
        return title ? filter('translate')(title) : '';
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(data) {
            var reason = '';

            if(reasonColumn) {
                if(full[reasonColumn]) {
                    reason = full[reasonColumn];
                }
            }

            content += '<i class="fa fa-fw fa-warning text-danger" title="' + reason + '" aria-hidden="true" ></i><span class="sr-only">' + reason + '</span>';
        }

        return content
    }
}

var CircleEnumStateColumn = function(filter, title, states) {
    this.filter = filter;
    this.title = title;
    this.states = states;

    this.getTitle = function () {
        return title ? filter('translate')(title) : '';
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';

        if(data) {
            var state = $linq(states).firstOrDefault(undefined, "x => x.id == '" + data + "'");

            if(state) {
                content = '<i class="' + state.class + ' fa fa-circle" title="' + filter('translate')(state.name) + '" aria-hidden="true"></i><span class="sr-only">' + filter('translate')(state.name) + '</span> ';
            }
        }

        return content;
    }
}

var DossierTransactionColumn = function($scope, filter, title, objectClass, objectPassed,variableName, startText, endText){
    this.getTitle = function () {
        return title ? filter('translate')(title) : '';
    }

    this.getRender = function(data, type, full, meta) {
        var content = '';
        if(data && data.length > 0 && variableName) {
            var dossierTransactions = data;
            if(objectPassed && objectPassed == 'dossierTransactionDocuments'){
                dossierTransactions = $linq(data).select("x => x.dossierTransaction").toArray();
            }
            if(dossierTransactions && dossierTransactions.length > 0) {
                $scope[variableName+full.id] = dossierTransactions;
                content = '<annexa-view-dossier-transaction dossier-transactions="'+variableName+full.id+'"';
                if(objectClass){
                    content += ' class="'+objectClass+'"';
                }
                if(startText){
                    content += ' start-text="'+startText+'"';
                }
                if(endText){
                    content += ' end-text="'+endText+'"';
                }
              content += '></annexa-view-dossier-transaction>';
            }
        }

        return content;
    }
}

var CommentColumn = function (filter, title, icon, object, user, noHref) {

    this.getTitle = function() {
        return title ? filter('translate')(title) : '';
    }

    this.getRender = function(data, type, full, meta) {
        var content = '<div class="text-center">';

        if(data) {
            var tooltip = '';
            if(data && Array.isArray(data)){
                angular.forEach(data, function(value, key){
                    if(object && value[object]){
                        if(user && value[user]){
                            tooltip += value[user]['name']+" "+ value[user]['surename1'];
                            if(value[user]['surename2']){
                                tooltip += " "+ value[user]['surename2'];
                            }
                            tooltip += " - ";
                        }
                        tooltip += value[object] + "\n";
                    }
                });
            }else{
                tooltip = data;
            }
            if(noHref) {
                if (tooltip) {
                    content += '<i  class="text-grey text-lg fa ' + icon + '" title="' + tooltip + '" aria-hidden="true"></i><span class="sr-only">' + tooltip + '</span>';
                } else {
                    content += '<i class="text-grey text-lg fa ' + icon + '-o" title="{{\'global.literals.empty_comments_title\' | translate}}" aria-hidden="true"></i><span class="sr-only" translate="global.literals.empty_comments"></span>';
                }
            }else{
                if (tooltip) {
                    content += '<a href="" tabindex="0" ng-click="createComment(' + meta.row + ')"><i  class="text-grey text-lg fa ' + icon + '" title="' + tooltip + '" aria-hidden="true"></i><span class="sr-only">' + tooltip + '</span></a>';
                } else {
                    content += '<a href="" tabindex="0" ng-click="createComment(' + meta.row + ')"><i class="text-grey text-lg fa ' + icon + '-o" title="{{\'global.literals.empty_comments\' | translate}}" aria-hidden="true"></i><span class="sr-only" translate="global.literals.empty_comments"></span></a>';
                }
            }
        }else{
            if(noHref){
                content += '<i class="text-grey text-lg fa '+icon+'-o" title="{{\'global.literals.empty_comments_title\' | translate}}" aria-hidden="true"></i><span class="sr-only" translate="global.literals.empty_comments"></span>';
            }else{
                content += '<a href="" tabindex="0" ng-click="createComment('+meta.row+')" ><i class="text-grey text-lg fa '+icon+'-o" title="{{\'global.literals.empty_comments\' | translate}}" aria-hidden="true"></i><span class="sr-only" translate="global.literals.empty_comments"></span></a>';
            }
        }

        content += '</div>';

        return content;
    }
}