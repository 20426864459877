/**
 * Created by osirvent on 01/08/2016.
 */
angular.module('annexaApp')
    .directive('annexaPresetSignActions',['$compile', '$filter', 'Language','RestService', function ($compile, $filter, Language, RestService) {
        var getTemplate = function(scope) {
            var template = '';
            template += '<div class="row scroll-x">';
            template += '   <table class="table m-a-0 table-striped">';
            template += '       <thead>';
            template += '           <tr>';
            template += '               <th style="width:17%"><span translate="global.literals.order">Order</span></th>';
            template += '               <th style="width:25%"><span translate="global.literals.type">Type</span></th>';
            template += '               <th style="width:25%"><span translate="global.literals.user_level">User / Level</span></th>';
            template += '               <th style="width:25%"><span translate="global.literals.action">Action</span></th>';
            template += '               <th style="width:17%"><span translate="global.literals.days">Days</span></th>';
            template += '               <th style="width:16%"></th>';
            template += '           </tr>';
            template += '       </thead>';
            template += '       <tr ng-repeat="action in presetSignActionDefinition.presetSignActions | orderBy : \'actionOrder\'">';
            template += '           <td class="v-middle">';
            template += '               <span  editable-number="action.actionOrder" e-min="1" e-max="{{presetSignActionDefinition.presetSignActions.length}}" e-name="actionOrder" e-form="rowform" onbeforesave="checkName($data)" e-required>';
            template += '                   {{ action.actionOrder || circuit.empty}}';
            template += '               </span>';
            template += '           </td>';
            template += '           <td class="v-middle">';
            template += '               <span editable-select="action.signatoryType" e-form="rowform" e-ng-change="changeType($data, $index)" e-ng-options="st as st[presetSignActionDefinition.languageColumn] for st in presetSignActionDefinition.signatoryTypes" onbeforesave="checkName($data)" e-required>';
            template += '                   {{ action.signatoryType[presetSignActionDefinition.languageColumn]}}';
            template += '               </span>';
            template += '           </td>';
            template += '           <td class="v-middle" ng-show="showUsers(action)">'
            template += '               <span editable-text="action.userSelect" e-form="rowform" e-uib-typeahead="result as result.value for result in loadUsers({val: $viewValue})" e-typeahead-loading="loadingBox" e-typeahead-no-results="noResultBox" e-typeahead-min-length="3" e-typeahead-wait-ms="300" e-typeahead-editable="false" onbeforesave="checkUserIdentifier($data,action)" e-required>';
            template += '                   {{ getUserName(action.user)}}';
            template += '               </span>';
            template += '           </td>';
            template += '           <td class="v-middle" ng-show="showLevel(action)">'
            template += '               <span  editable-number="action.actionLevel" e-min="1" e-max="20" e-name="actionLevel" e-form="rowform"  onbeforesave="checkActionLevel($data,action)" e-required>';
            template += '                   {{ action.actionLevel || circuit.empty}}';
            template += '               </span>';
            template += '           </td>';
            template += '           <td class="v-middle">';
            template += '               <span editable-select="action.signActionType" e-form="rowform" e-ng-options="sat as sat[presetSignActionDefinition.languageColumn] for sat in presetSignActionDefinition.signactionTypes" onbeforesave="checkName($data)" e-required>';
            template += '                   {{ action.signActionType[presetSignActionDefinition.languageColumn] }}';
            template += '               </span>';
            template += '           </td>';
            template += '           <td class="v-middle">';
            template += '               <span  editable-number="action.maxDurationDays" e-min="1" e-name="maxDurationDays" e-form="rowform" onbeforesave="checkName($data)" e-required onbeforesave="">';
            template += '                   {{ action.maxDurationDays || circuit.empty }}';
            template += '               </span>';
            template += '           </td>';
            template += '           <td class="text-nowrap">';
            template += '               <div class="pull-right">';
            template += '                   <form class="form-buttons form-inline" editable-form name="rowform" onbeforesave="saveAction($data, $index)" onaftersave="afterSaveAction($index)" ng-show="rowform.$visible" shown="presetSignActionDefinition.inserted == action">';
            template += '                       <button type="submit" ng-disabled="rowform.$waiting" class="btn btn-sm primary">';
            template += '                           <span translate="global.literals.save">Save</span>';
            template += '                       </button>';
            template += '                       <button type="button" ng-disabled="rowform.$waiting" ng-click="cancelAction($index, rowform)" class="btn btn-sm grey-500 text-white">';
            template += '                           <span translate="global.literals.cancel">Cancel</span>';
            template += '                       </button>';
            template += '                   </form>';
            template += '                   <div class="buttons" ng-show="!rowform.$visible">';
            template += '                       <button class="btn btn-sm no-bg" ng-click="rowform.$show()"><i class="fa fa-pencil grey-pencil" title="{{ \'global.literals.edit\' | translate}}"></i></button>';
            template += '                       <button class="btn btn-sm no-bg " ng-show="presetSignActionDefinition.presetSignActions.length > 1" ng-click="removeAction($index)"><i class="fa  fa-remove grey-pencil" aria-hidden="true"></i><span class="sr-only">{{ \'global.literals.remove\' | translate }}</span></button>';
            template += '                   </div>';
            template += '               </div>';
            template += '           </td>';
            template += '       </tr>';
            template += '    </table>';
            template += '    <div class="dker p-a b-t">';
            template += '           <div class="row">';
            template += '               <button class="btn btn-sm primary m-l" ng-click="addAction()"><i class="fa fa-plus"></i> <span translate="global.literals.addAction">Add Action</span></button>';
            template += '           </div>';
            template += '    </div>';
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope:{
                presetSignActionDefinition: '='
            },
            transclude: true,
            link: function(scope, element, attr, ctrl, transclude) {
                scope.users = [];

                scope.showUsers = function(action) {
                    var $rootScope = angular.element(document.body).injector().get('$rootScope');

                    if(action.signatoryType) {
                        if (action.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                };

                scope.showLevel = function(action) {
                    var $rootScope = angular.element(document.body).injector().get('$rootScope');

                    if(action.signatoryType) {
                        if (action.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value) {
                            return false
                        } else {
                            return true
                        }
                    } else {
                        return true;
                    }
                };

                scope.checkName = function(data){
                    if (!data) {
                        return $filter('translate')('global.validation.required');
                    }
                }
                scope.changeType = function (data, index) {
                    scope.presetSignActionDefinition.presetSignActions[index].signatoryType = data;
                    scope.presetSignActionDefinition.presetSignActions[index].actionLevel = undefined;
                    scope.presetSignActionDefinition.presetSignActions[index].user  = undefined;
                };
                scope.loadUsers = function(data){
                    return RestService.loadData('common','User',data.val).then(function (data) {
                        var response = [];
                        angular.forEach(JSOG.decode(data.data).content, function(value, key) {
                            var name = value.name;
                            if(value.surename1){ name = name + " "+value.surename1};
                            if(value.surename2){name = name + " "+value.surename2};
                            response.push({ id: value.id, object: {id:value.id, name: value.name, surename1:value.surename1, surename2:value.surename2}, value: name });
                        });
                        return response;
                    });
                };
                scope.addAction = function (){
                    var inserted = {
                        actionLevel:null,
                        user: null,
                        signatoryType:null,
                        actionOrder: scope.presetSignActionDefinition.presetSignActions.length+1,
                        signActionType: null,
                        maxDurationDays: 5
                    };
                    scope.presetSignActionDefinition.inserted = inserted;
                    scope.presetSignActionDefinition.presetSignActions.push(inserted);
                }
                scope.removeAction = function(index) {
                	scope.presetSignActionDefinition.presetSignActions.sort(scope.compare);
                	var orderDelete = scope.presetSignActionDefinition.presetSignActions[index].actionOrder;
                    if(scope.presetSignActionDefinition.removeActionFunction){
                    	for(var i = 0; i < scope.presetSignActionDefinition.presetSignActions.length; i++){
                    		var userCircuit = scope.presetSignActionDefinition.presetSignActions[i];
                    		if(orderDelete < userCircuit.actionOrder && orderDelete != userCircuit.actionOrder){
                            	userCircuit.actionOrder = userCircuit.actionOrder - 1;
                            }
                        }
                        scope.presetSignActionDefinition.removeActionFunction(index);
                    }
                };

                scope.cancelAction = function(index, rowform){
                    if(scope.presetSignActionDefinition.presetSignActions[index]&& !scope.presetSignActionDefinition.presetSignActions[index].id){
                        scope.presetSignActionDefinition.presetSignActions.splice(index, 1);
                    }
                    rowform.$cancel();
                }
                scope.saveAction = function(data, id) {
                    angular.extend(data, {id: id});
                    if(data.actionOrder != null){
                        if(data.actionOrder !=  scope.presetSignActionDefinition.presetSignActions[id].actionOrder) {
                            var existOrder = false;
                            var orderActualNull = (scope.presetSignActionDefinition.presetSignActions[id].actionOrder == null || scope.presetSignActionDefinition.presetSignActions[id].actionOrder == undefined);
                            for(var i = 0; i < scope.presetSignActionDefinition.presetSignActions.length; i++){
                                if(scope.presetSignActionDefinition.presetSignActions[i].actionOrder == data.actionOrder){
                                    existOrder = true;
                                    break;
                                }
                            }
                            if(existOrder){
                            	var actualOrder = scope.presetSignActionDefinition.presetSignActions[id].actionOrder;
                            	var newOrder = data.actionOrder;
                                for (var i = 0; i <scope.presetSignActionDefinition.presetSignActions.length; i++) {
                                	
                                	var userCircuit = scope.presetSignActionDefinition.presetSignActions[i];
                                	if(!orderActualNull) {
                                		if (userCircuit.idTable != id) {
                                			if(actualOrder < newOrder) {
                                				if(actualOrder < userCircuit.actionOrder && newOrder >= userCircuit.actionOrder) {
                                    				userCircuit.actionOrder = userCircuit.actionOrder - 1;
                                    			}
                                			} else if(actualOrder > newOrder) {
                                				if(actualOrder > userCircuit.actionOrder && newOrder <= userCircuit.actionOrder) {
                                    				userCircuit.actionOrder = userCircuit.actionOrder + 1;
                                    			}
                                			}
                                		} 
                                	}else {
                                		if (userCircuit.idTable != id) {
                                            if(userCircuit.actionOrder > newOrder){
                                                userCircuit.actionOrder = userCircuit.actionOrder + 1;
                                            }else if(userCircuit.actionOrder == newOrder){
                                                if (newOrder == scope.presetSignActionDefinition.presetSignActions.length) {
                                                    userCircuit.actionOrder = userCircuit.actionOrder - 1;
                                                } else {
                                                    userCircuit.actionOrder = userCircuit.actionOrder + 1;
                                                }
                                            }
                                        }
                                	}
                                }
                            }
                        }
                    }
                };
                
                scope.compare = function( a, b ) {
                	if ( a.actionOrder < b.actionOrder ){
                		return -1;
                	}
                	if ( a.actionOrder > b.actionOrder ){
                		return 1;
                	}
                	return 0;
                }
                
                scope.presetSignActionDefinition.presetSignActions.sort(scope.compare);

                scope.afterSaveAction = function(index){
                    if(scope.presetSignActionDefinition.saveActionFunction){
                        scope.presetSignActionDefinition.saveActionFunction(index);
                        scope.presetSignActionDefinition.presetSignActions.sort(scope.compare);
                    }
                }
                scope.checkUserIdentifier = function (data, action) {
                    var $rootScope = angular.element(document.body).injector().get('$rootScope');
                    if(action.signatoryType && action.signatoryType.id == $rootScope.app.configuration.signatory_type_user.value){
                        if (data == null || data == undefined || data == '') {
                            return $filter('translate')('global.validation.required');
                        }else{
                            if(data.object){
                                action.user = data.object;
                            }else{
                                action.user = undefined;
                                return  $filter('translate')('global.validation.required');
                            }
                        }
                    }
                };
                scope.checkActionLevel=function(data, action){
                    var $rootScope = angular.element(document.body).injector().get('$rootScope');
                    if(action.signatoryType && action.signatoryType.id != $rootScope.app.configuration.signatory_type_user.value){
                        if (!data) {
                            return $filter('translate')('global.validation.required');
                        }
                    }
                }
                scope.getUserName = function (user) {
                    if (user) {
                        var name = user.name;
                        if(user.surename1){ name = name + " "+user.surename1};
                        if(user.surename2){name = name + " "+user.surename2};
                        return name;
                    }else{
                        return "";
                    }
                };
                element.html(getTemplate(scope.presetSignActionDefinition)).show();
                $compile(element.contents())(scope);
            }
        }

    }]);