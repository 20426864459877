/**
 * Created by osirvent on 27/04/2016.
 */
angular.module('annexaApp')
    .directive('annexaFormly',['$compile', '$rootScope', function($compile, $rootScope) {
        var getTemplate = function (formObject) {
            var template = '';

            if(formObject.submit instanceof AnnexaFormlySubmitButton) {
                template += '<form novalidate>';
                template += '<formly-form model="form.model" fields="form.fields" form="form.form" root-el="' + formObject.rootEl  + '" novalidate="true">';
                template += '<button type="submit" class="' + formObject.submit.classes + '" translate="' + formObject.submit.label + '" ng-click="submit()">' + formObject.submit.label + '</button>';
            } else  {
                template += '<form ng-submit="submit()" name="form.form" novalidate>';
                template += '<formly-form model="form.model" fields="form.fields" options="form.options" form="form.form" root-el="' + formObject.rootEl  +'" novalidate="true">';
                template += '<button type="submit">test</button>';
            }
            template += '</formly-form>';
            template += '</form>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                form: '=formObject',
                submit: '&formSubmit'
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope.form)).show();

                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaContent',['$compile', function ($compile) {
        return {
            restrict: 'A',
            scope: {
                annexaContent: '@',
                objectScope: '='
            },
            link: function(scope, element, attr) {
                var compiled = $compile(attr['annexaContent'])(scope);
                element.html(compiled).show();
            }
        }
    }])
    .directive('annexaFormlyModal',['$compile', '$rootScope', '$timeout', function ($compile, $rootScope, $timeout) {
        var getTemplate = function (modalObject, showdebuginfo, hideSubmitButton, id, containTabs, buttonLabel, containWizard) {
            var template = '';
            
            template += '<div class="modal-dialog '+ modalObject.size + '">';
            template += '   <div class="modal-content">';
            template += '       <div class="modal-header">';
            template += '           <div class="row">';
            template += '               <div class="col-sm-12">';
            template += '                   <h5 class="modal-title" tabindex="0">';
            if(modalObject.icon && !modalObject.icon.startsWith('&#x')) {
                template += '<i class="' + modalObject.icon + '" aria-hidden="true"></i> ';
            } else if(modalObject.icon && modalObject.icon.startsWith('&#x')) {
                template += '<i class="material-icons md-36" aria-hidden="true">' + modalObject.icon + '</i> ';
            }
            template += '                       <span translate="' + modalObject.title + '">' + modalObject.title + '</span>';
            template += '                   </h5>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="modal-body">';
            template += '           <div uib-alert ng-repeat="alert in modal.alerts" class="alert alert-{{alert.type ? alert.type : \'danger\'}} alert-dismissable" close="closeAlert($index)">{{alert.msg | translate}}</div>';
            if(containWizard) {
                template += '           <form name="modal.annexaFormly.form">';
            } else {
                template += '           <form ng-submit="submitform()" name="modal.annexaFormly.form" novalidate>';
            }
            if(containTabs) {
                template += '           <div class="nav-active-primary">';
                template += '               <uib-tabset active="activeTabIndex">';
                template += '                   <uib-tab class="nav-item" ng-repeat="tab in modal.tabs" heading="{{tab.title | translate}}" ng-show="!tab.hideExpression || !tab.hideExpression(modal)">';
                template += '                       <formly-form model="tab.annexaFormly.model" fields="tab.annexaFormly.fields" options="tab.annexaFormly.options" form="tab.annexaFormly.form">';
                template += '                       </formly-form>';
                template += '                       <div class="row" ng-if="tab.content">';
                template += '                           <div class="col-sm-12">';
                template += '                               <div annexa-content="{{tab.content.join(\'\')}}" object-scope="modal"></div>';
                template += '                           </div>';
                template += '                       </div>';
                template += '                   </uib-tab>';
                template += '                   <uib-tab classes="nav-item" heading="Submit" style="display: none;">';
                template += '                       <button type="submit" class="btn btn-primary submit-button" id="smFormModal' + id + '" style="display: none;">Submit</button>';
                template += '                   </uib-tab>';
                template += '               </uib-tabset>';
                template += '           </div>';
            } else if (containWizard) {
                template += '           <wizard on-finish="modal.wizard.finishWizard()" hide-indicators="true" name="modal-wizard">';
                template += '               <wz-step ng-repeat="step in modal.wizard.steps" title="{{step.title | translate}}" canexit="step.exitValidation != undefined ? step.exitValidation(step.annexaFormly.model, step, modal.wizard) : modal.wizard.exitValidation(step.annexaFormly.form)" canenter="step.enterStep ? step.enterStep(step.annexaFormly.model, step, modal.wizard) : umdefined" wz-disabled="{{step.disabled}}">';
                template += '                   <h5 class=" ">';
                template += '                       <span translate="{{step.title}}">{{step.title}}</span>';
                template += '                   </h5>';
                template += '                   <formly-form model="step.annexaFormly.model" fields="step.annexaFormly.fields" options="step.annexaFormly.options" form="step.annexaFormly.form">';
                template += '                       <div class="text-right wizard-buttons">'
                template += '                           <button type="submit" ng-disabled="$first" wz-previous class="btn btn-sm text-white grey-500" ng-if="!step.afterNext">';
                template += '                               <i class="fa fa-chevron-left"></i>';
                template += '                               <span translate="global.literals.previous">Previous</span>';
                template += '                           </button>';
                template += '                           <button type="submit" ng-disabled="$first" wz-previous="step.afterNext(step,modal.wizard)" class="btn btn-sm text-white grey-500" ng-if="step.afterNext">';
                template += '                               <i class="fa fa-chevron-left"></i>';
                template += '                               <span translate="global.literals.previous">Previous</span>';
                template += '                           </button>';
                template += '                           <button type="submit" ng-disabled="step.disableNext(step.annexaFormly.model, modal.wizard) || $last || step.isFinal" wz-next class="btn btn-sm text-white grey-500" ng-if="!step.beforeNext">';
                template += '                               <span translate="global.literals.next">Next</span>';
                template += '                               <i class="fa fa-chevron-right"></i>';
                template += '                           </button>';
                template += '                           <button type="submit" ng-disabled="step.disableNext(step.annexaFormly.model, modal.wizard) || $last || step.isFinal" wz-next="step.beforeNext(step,modal.wizard)" class="btn btn-sm text-white grey-500" ng-if="step.beforeNext">';
                template += '                               <span translate="global.literals.next">Next</span>';
                template += '                               <i class="fa fa-chevron-right"></i>';
                template += '                           </button>';
                template += '                           <button type="submit" ng-if="!isFirstLast()" ng-disabled="step.annexaFormly.form.$invalid || (!$last && !step.isFinal)" wz-finish class="btn btn-sm text-white primary btn-primary">';
                template += '                               <span translate="global.literals.finish">Finish</span>';
                template += '                           </button>';
                template += '                           <button type="submit" ng-if="isFirstLast()" ng-disabled="!isFirstLastValid()" wz-finish class="btn btn-sm text-white primary btn-primary">';
                template += '                               <span translate="global.literals.finish">Finish</span>';
                template += '                           </button>';
                template += '                       </div>';
                template += '                   </formly-form>';
                template += '               </wz-step>';
                template += '           </wizard>';
            } else {
                template += '           <formly-form model="modal.annexaFormly.model" fields="modal.annexaFormly.fields" options="modal.annexaFormly.options" form="modal.annexaFormly.form">';
                template += '               <button type="submit" class="btn btn-primary submit-button" id="smFormModal' + id + '" style="display: none;">Submit</button>';
                template += '           </formly-form>';
            }
            template += '           </form>';
            template += '           <div class="row" ng-repeat="box in modal.boxes">';
            template += '               <annexa-box definition="box.boxDefinition">';
            template += '                   <annexa-entity-render-box definition="definition" type="{{definition.renderType}}"/>';
            template += '               </annexa-box>';
            template += '           </div>';
            template += '       </div>';
            template += '               <div class=modal-footer>';
            template += '                   <button ng-if="modal.additionalButton" type="button" class="btn btn-sm primary" ng-click="modal.additionalButton.click(modal)">{{modal.additionalButton.label | translate}}</button>';
            if(!hideSubmitButton){
                if(buttonLabel) {
                    template += '                   <button type="button" class="btn btn-sm primary" ng-click="save()"><i class="fa fa-floppy-o" aria-hidden="true"></i> <span translate="'+buttonLabel+'">Save</span></button>';
                }else{
                    template += '                   <button type="button" class="btn btn-sm primary" ng-click="save()"><i class="fa fa-floppy-o" aria-hidden="true"></i> <span translate="global.literals.save">Save</span></button>';
                }
            }
            template += '                   <button type="button" class="btn btn-sm grey-500  text-white" data-dismiss="modal" ng-click="modal.close();"><span translate="global.literals.cancel">Cancel</span></button>';
            template += '               </div>';
            if(showdebuginfo) {
                template += '       <h2>Form Data</h2>';
                template += '       <pre>{{modal.annexaFormly.model | json}}</pre>';
                template += '       <h2>Fields <small>(note, functions are not shown)</small></h2>';
                template += '       <pre>{{modal.annexaFormly.originalFields | json}}</pre>';
                template += '       <h2>Form</h2>';
                template += '       <pre>{{modal.annexaFormly.form | json}}</pre>';
            }
            template += '   </div>';
            template += '</div>';

            return template;
        }
        return {
            restrict: 'E',
            replace: true,
            scope: {
                modal: '=modalObject',
                showdebuginfo: '=',
                hidesubmitbutton:'=hidesubmitbutton'
            },
            link: function (scope, element, attr) {
                var idElement = '';
                scope.containTabs = false;
                scope.containWizard = false;

                if(scope.modal.hasOwnProperty('tabs')) {
                    scope.containTabs = true;
                    scope.containWizard = false;
                    scope.activeTabIndex = 0;
                    if(scope.modal.tabs && scope.modal.tabs.length > 0){
                        scope.activeTabIndex = 1;
                        angular.forEach(scope.modal.tabs, function (tab, key) {
                            if(tab.active){
                                scope.activeTabIndex = key+1;
                            }
                        });
                    }
                }

                if(scope.modal.hasOwnProperty('wizard')) {
                    scope.containWizard = true;
                    scope.containTabs = false;
                }

                if(attr.id){
                    idElement = attr.id;
                }
                if(attr.buttonlabel){
                    scope.buttonlabel = attr.buttonlabel;
                }
                
                scope.isFirstLast = function(){
                	var isFirstLast = false;
                	if(scope.modal.canFirstClose && scope.modal.hasOwnProperty('wizard')) {
                		if(scope.modal.wizard && scope.modal.wizard.steps && scope.modal.wizard.steps.length > 0){
                			var i = 0;
                			var first = false;
                			_.forEach(scope.modal.wizard.steps, function(step, index){
                				if(index == 0 && step.disabled == false){
                					first = true;
                					i++;
                				}else if(step.disabled == false){
                					i++;
                				}
                			});
                			if(first && i == 1){
                				isFirstLast = true;
                			}
                		}
                    }
                	return isFirstLast;
                };
                
                scope.isFirstLastValid = function(){
                	var isValid = false;
                	if(scope.modal.canFirstClose && scope.modal.hasOwnProperty('wizard')) {
                		if(scope.modal.wizard && scope.modal.wizard.steps && scope.modal.wizard.steps.length > 0){
                			isValid = scope.modal.wizard.steps[0].exitValidation(scope.modal.wizard.steps[0].annexaFormly.model);
                		}
                    }
                	return isValid;
                };
                
                element.html(getTemplate(scope.modal, scope.showdebuginfo, scope.hidesubmitbutton, idElement, scope.containTabs, scope.buttonlabel, scope.containWizard)).show();

                scope.save = function() {
                    $timeout(function () {
                        angular.element('#smFormModal'+idElement).trigger('click');
                    })
                }

                scope.submitform = function() {
                    if(!scope.modal.tabs) {
                        scope.modal.annexaFormly.form.$setSubmitted();
                        if (scope.modal.annexaFormly.form.$valid) {
                            if (scope.modal.submitModal) {
                                scope.modal.submitModal(scope.modal);
                            }
                        }
                    } else {
                        var validAllTabs = true;
                        angular.forEach(scope.modal.tabs, function (tab) {
                            tab.annexaFormly.form.$setSubmitted();
                            validAllTabs = validAllTabs && tab.annexaFormly.form.$valid;
                        });
                        if(validAllTabs) {
                            if(scope.modal.submitModal) {
                                scope.modal.submitModal(scope.modal);
                            }
                        }
                    }
                }

                scope.closeAlert = function(index) {
                    scope.modal.alerts.splice(index,1);
                }

                $compile(element.contents())(scope);
            }
        }
    }])
    .controller('AnnexaFormlyModalController',['$scope','close', function($scope,close) {
        $scope.close = function() {
            if(!this.tabs) {
                if (this.annexaFormly && this.annexaFormly.options) {
                    this.annexaFormly.options.resetModel();
                }
            } else {
                angular.forEach(this.tabs, function(tab) {
                    tab.annexaFormly.options.resetModel();
                });
            }

            angular.element('body').attr("data-temp","obert");
            angular.element('body').removeClass('modal-open');

            if(this.preClose) {
                this.preClose();
            }

            close({},200);
        };

        $scope.$on('closeAnnexaFormModal', function (event, args) {
            if($scope.modalObj.id == args.id) {
                $scope.close();
            }
        })
    }]);