/**
 * Created by osirvent on 31/01/2017.
 */
angular.module('annexaApp')
    .factory('ABMValidationFactory',['$rootScope', 'Language', 'ABMShapesFactory', '$filter', 'ABMModelFactory', function ($rootScope, Language, ABMShapesFactory, $filter, ABMModelFactory) {
        var factory = {};

        factory.ABMComponentFactory = undefined;
        factory.validations = [];

        //region General

        factory.setABMComponentFactory = function (ComponentFactory) {
            factory.ABMComponentFactory = ComponentFactory;
        };

        factory.subscribe = function (scope, callback) {
            var handler = $rootScope.$on('bpmValidation', callback);
            scope.$on('$destroy', handler);
        };

        //endregion

        //region Helpers

        var getCellDescriptor = function (cell) {
            var id = cell.get('id');

            var transaction = undefined;

            _.forOwn(factory.ABMComponentFactory.scope.model.transactions, function(value, key) {
                if(key == id) {
                    transaction = value;
                }
            });

            if(transaction && transaction[Language.getActiveColumn()]) {
                return transaction[Language.getActiveColumn()];
            } else {
                return id;
            }
        };

        var getByType = function(type) {
            return ABMShapesFactory.getShapesByType(type, factory.ABMComponentFactory.graph.getCells());
        };

        var checkByTypeAtLeastOneInLink = function(type) {
            var cells = factory.ABMComponentFactory.graph.getCells();

            return $linq(cells).count(function(x) {
                    return ABMShapesFactory.getShapeType(x) == type && countInLinks(x) > 0
                }) == getByType(type).length;
        }

        var checkByTypeAtLeastOneOutLink = function(type) {
            var cells = factory.ABMComponentFactory.graph.getCells();

            return $linq(cells).count(function(x) {
                    return ABMShapesFactory.getShapeType(x) == type && ABMShapesFactory.countOutLinks(x) > 0
                }) == getByType(type).length;
        }

        var checkByTypeAtLeastNumOutLink = function(type, num) {
            var cells = factory.ABMComponentFactory.graph.getCells();

            return $linq(cells).count(function(x) {
                    return ABMShapesFactory.getShapeType(x) == type && ABMShapesFactory.countOutLinks(x) >= num
                }) == getByType(type).length;
        }

        var existByType = function(type) {
            var cells = factory.ABMComponentFactory.graph.getCells();

            return $linq(cells).count(function (x) {
                    return ABMShapesFactory.getShapeType(x) == type;
                }) != 0;
        }

        var countByType = function(type) {
            var cells = factory.ABMComponentFactory.graph.getCells();

            return $linq(cells).count(function (x) {
                return ABMShapesFactory.getShapeType(x) == type
            });
        }

        var getSingleByType = function (type) {
            var cells = factory.ABMComponentFactory.graph.getCells();

            return $linq(cells).single(function (x) {
                return ABMShapesFactory.getShapeType(x) == type;
            });
        };

        var countInLinks = function (cell) {
            return factory.ABMComponentFactory.graph.getConnectedLinks(cell, { inbound: true }).length;
        }

        var countInLinksById = function(cellId) {
            var cell = factory.ABMComponentFactory.graph.getCell(cellId);

            if(cell) {
                return countInLinks(cell);
            } else {
                return -1;
            }
        }

        var countOutLinksById = function(cellId) {
            var cell = factory.ABMComponentFactory.graph.getCell(cellId);

            if(cell) {
                return ABMShapesFactory.countOutLinks(cell);
            } else {
                return -1;
            }
        }

        //endregion

        //region Halo Validations

        factory.setupHalo = function(cell, halo) {

            //region Inici

            if(ABMShapesFactory.getShapeType(cell) == ABMShapesFactory.TI) {
                halo.removeHandle('remove');
            }

            //endregion

            if(_.contains([ABMShapesFactory.TI, ABMShapesFactory.TEN, ABMShapesFactory.TEF, ABMShapesFactory.TFN, ABMShapesFactory.TFF, ABMShapesFactory.SN, ABMShapesFactory.SFN], ABMShapesFactory.getShapeType(cell)) && ABMShapesFactory.countOutLinks(cell) == 1) {
                halo.removeHandle('link');
            }
            halo.removeHandle('clone');
            halo.removeHandle('fork');

            return halo;
        }

        //endregion

        //region Modeler Connections Validations

        var externalPath = function(path) {
            if(!path) {
                return undefined;
            }

            var element = ABMShapesFactory.getOpenGatewayById(path.gatewayId);

            if(ABMShapesFactory.getShapeType(element) == ABMShapesFactory.TI) {
                return path;
            } else {
                return externalPath(element.get('gatewayPath'));
            }
        };

        var validatePath = function(path, extPath, element, elements) {
            if(_.contains(elements, element.id)) {
                return true;
            } else if( _.contains([ABMShapesFactory.TEF, ABMShapesFactory.TFF], ABMShapesFactory.getShapeType(element))) {
                return false;
            } else if(ABMShapesFactory.isOpenGatewayPath(element, extPath) || ABMShapesFactory.isCloseGatewayPath(element, extPath)) {
                return false;
            }

            elements.push(element.id);

            if(ABMShapesFactory.isCloseGateway(element)) {
                if(!validatePath(path, extPath, ABMShapesFactory.getOpenGateway(element),elements)) {
                    return false;
                }
            } else {
                var ret = false;
                _.forEach(ABMShapesFactory.getInLinks(element), function(link) {
                    if(!ret) {
                        if(!validatePath(path, extPath, link.getSourceElement(), elements)) {
                            ret = true;
                        }
                    }
                });
                if(ret) {
                    return false;
                }
            }

            if(ABMShapesFactory.isOpenGateway(element)) {
                if(!validatePath(path, extPath, ABMShapesFactory.getCloseGateway(element), elements)) {
                    return false;
                }
            } else {
                var ret = false;
                _.forEach(ABMShapesFactory.getOutLinks(element), function(link) {
                    if(!ret) {
                        if(!validatePath(path, extPath, link.getTargetElement(), elements)) {
                            ret = true;
                        }
                    }
                });
                if(ret) {
                    return false;
                }
            }

            return true;
        }
        var validateLink = function (source, target) {
            if(!source || !target) {
                return false;
            }

            if(ABMShapesFactory.isOpenGateway(source)) {
                if(!ABMShapesFactory.hasPath(target)) {
                    return true;
                } else {
                    return false;
                }
            } else if(ABMShapesFactory.isCloseGateway(target)) {
                if(ABMShapesFactory.inPath(source,target)) {
                    return true;
                } else {
                    return false;
                }
            } else if(!ABMShapesFactory.hasPath(source) && !ABMShapesFactory.hasPath(target)) {
                return true;
            } else if(!ABMShapesFactory.hasPath(source) && ABMShapesFactory.hasPath(target)) {
                var tarPath = ABMShapesFactory.getPath(target);
                var extPath = externalPath(tarPath);
                var elementPath = ABMShapesFactory.getOpenGatewayById(tarPath.gatewayId); 
                var elementExtPath = undefined;
                if(extPath) {
                    elementExtPath = ABMShapesFactory.getOpenGatewayById(extPath.gatewayId); 
                }

                if(ABMShapesFactory.getShapeType(elementPath) == ABMShapesFactory.TI) {
                    return true;
                } else {
                    return validatePath(tarPath, extPath, source, [target.id]);
                }
            } else if(ABMShapesFactory.hasPath(source) && !ABMShapesFactory.hasPath(target)) {
                var souPath = ABMShapesFactory.getPath(source);
                var extPath = externalPath(souPath);
                var elementPath = ABMShapesFactory.getOpenGatewayById(souPath.gatewayId); 
                var elementExtPath = undefined;
                if(extPath) {
                    elementExtPath = ABMShapesFactory.getOpenGatewayById(extPath.gatewayId); 
                }

                if(ABMShapesFactory.getShapeType(elementPath) == ABMShapesFactory.TI) {
                    return true;
                } else {
                    return validatePath(souPath, extPath, target, [source.id]);
                }
            } else if (ABMShapesFactory.isEqualPath(ABMShapesFactory.getPath(source), ABMShapesFactory.getPath(target))) {
                return true;
            } else if (!ABMShapesFactory.isEqualPath(ABMShapesFactory.getPath(source), ABMShapesFactory.getPath(target))) {
                return false;
            }

            return false;
        }

        factory.validateConnection = function(source, target) {

            //region General

            if(source.id == target.id) {
                return false;
            }

            if(target.get('type') == 'bpmn.Flow') {
                return false;
            }

            //endregion

            var equalPath = function(path1, path2) {
                return path1.gatewayId == path2.gatewayId && path1.path == path2.path;
            }

            var validateLinkOld = function(s, t) {
                var sourcePath = s.get('gatewayPath');
                var targetPath = t.get('gatewaypath');

                if(!sourcePath && !targetPath) {
                    return true;
                } else if(!sourcePath && targetPath) {
                    return false;
                } else if(sourcePath && !targetPath) {
                    return true;
                } else {
                    if (equalPath(sourcePath, targetPath)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }

            //region Inici

            if(ABMShapesFactory.getShapeType(source) == ABMShapesFactory.TI) {
                if(!_.contains([ABMShapesFactory.TI, ABMShapesFactory.GSMC, ABMShapesFactory.GSAC], ABMShapesFactory.getShapeType(target))) {
                    return true && validateLink(source, target);
                }
            }

            //endregion

            //region Establerta Normal

            if(ABMShapesFactory.getShapeType(source) == ABMShapesFactory.TEN) {
                if(ABMShapesFactory.getShapeType(target) != ABMShapesFactory.TI) {
                    return true && validateLink(source, target);
                }
            }

            //endregion

            //region Establerta Finalitzable


            if(ABMShapesFactory.getShapeType(source) == ABMShapesFactory.TEF) {
                if(!_.contains([ABMShapesFactory.TI, ABMShapesFactory.GSMC, ABMShapesFactory.GSAC], ABMShapesFactory.getShapeType(target))) {
                    return true && validateLink(source, target);
                }
            }

            //endregion

            //region Lliure Normal

            if(ABMShapesFactory.getShapeType(source) == ABMShapesFactory.TFN) {
                if(!_.contains([ABMShapesFactory.TI, ABMShapesFactory.TFN, ABMShapesFactory.TFF], ABMShapesFactory.getShapeType(target))) {
                    return true && validateLink(source, target);
                }
            }

            //endregion

            //region Lliure Finalitzable

            if(ABMShapesFactory.getShapeType(source) == ABMShapesFactory.TFF) {
                if(!_.contains([ABMShapesFactory.TI, ABMShapesFactory.TFN, ABMShapesFactory.TFF, ABMShapesFactory.GSMC, ABMShapesFactory.GSAC], ABMShapesFactory.getShapeType(target))) {
                    return true && validateLink(source, target);
                }
            }

            //endregion

            //region Subprocès Normal

            if(ABMShapesFactory.getShapeType(source) == ABMShapesFactory.SN || ABMShapesFactory.getShapeType(source) == ABMShapesFactory.SFN) {
                if(ABMShapesFactory.getShapeType(target) != ABMShapesFactory.TI) {
                    return true && validateLink(source, target);
                }
            }

            //endregion

            //region Tria'n un

            if(ABMShapesFactory.getShapeType(source) == ABMShapesFactory.GSO) {
                if(!_.contains([ABMShapesFactory.TI, ABMShapesFactory.GSO, ABMShapesFactory.GSMC, ABMShapesFactory.GSAC], ABMShapesFactory.getShapeType(target))) {
                    return true && validateLink(source, target);
                }
            }

            //endregion

            //region Tria'n diversos Obertura

            if(ABMShapesFactory.getShapeType(source) == ABMShapesFactory.GSMO) {
                if(!_.contains([ABMShapesFactory.TI, ABMShapesFactory.TEF, ABMShapesFactory.TFF, ABMShapesFactory.GSMC, ABMShapesFactory.GSAC], ABMShapesFactory.getShapeType(target))) {
                    return true && validateLink(source, target);
                }
            }

            //endregion

            //region Tria'n diversos Tancament

            if(ABMShapesFactory.getShapeType(source) == ABMShapesFactory.GSMC) {
                if(ABMShapesFactory.getShapeType(target) != ABMShapesFactory.TI) {
                    return true && validateLink(source, target);
                }
            }

            //endregion

            //region Tria'ls Tots Obertura

            if(ABMShapesFactory.getShapeType(source) == ABMShapesFactory.GSAO) {
                if(!_.contains([ABMShapesFactory.TI, ABMShapesFactory.TEF, ABMShapesFactory.TFF, ABMShapesFactory.GSMC, ABMShapesFactory.GSAC], ABMShapesFactory.getShapeType(target))) {
                    return true && validateLink(source, target);
                }
            }

            //endregion

            //region Tria'ls Tots Tancament

            if(ABMShapesFactory.getShapeType(source) == ABMShapesFactory.GSAC) {
                if(ABMShapesFactory.getShapeType(target) != ABMShapesFactory.TI) {
                    return true && validateLink(source, target);
                }
            }

            //endregion

            return false;
        }

        //endregion

        //region BPM Validations

        factory.validateBPM = function () {
            var graphValidations = [];

            var opentGW = $linq(factory.ABMComponentFactory.graph.getCells()).where(function (x) {
                return _.contains([ABMShapesFactory.GSO, ABMShapesFactory.GSMO, ABMShapesFactory.GSAO], ABMShapesFactory.getShapeType(x));
            }).toArray();


            var hasOpenGatewayValidations = false;
            var hasCloseGatewayValidations = false;

            _.forEach(opentGW, function (gateway) {
                if(ABMShapesFactory.countInLinks(gateway) < 1 || ABMShapesFactory.countOutLinks(gateway) < 2) {
                    hasOpenGatewayValidations = true;
                    ABMShapesFactory.setGatewayError(gateway);

                    if(ABMShapesFactory.getShapeType(gateway) != ABMShapesFactory.GSO) {
                        var closeGateway = ABMShapesFactory.getCloseGateway(gateway);

                        if(ABMShapesFactory.countInLinks(closeGateway) < ABMShapesFactory.countOutLinks(gateway) || ABMShapesFactory.countOutLinks(closeGateway) != 1) {
                            hasCloseGatewayValidations = true;
                            ABMShapesFactory.setGatewayError(closeGateway);
                        } else {
                            ABMShapesFactory.unsetGatewayError(closeGateway);
                        }
                    }
                } else {
                    ABMShapesFactory.unsetGatewayError(gateway)

                    if(ABMShapesFactory.getShapeType(gateway) != ABMShapesFactory.GSO) {
                        var closeGateway = ABMShapesFactory.getCloseGateway(gateway);

                        if(ABMShapesFactory.countInLinks(closeGateway) < ABMShapesFactory.countOutLinks(gateway) || ABMShapesFactory.countOutLinks(closeGateway) != 1) {
                            hasCloseGatewayValidations = true;
                            ABMShapesFactory.setGatewayError(closeGateway);
                        } else {
                            ABMShapesFactory.unsetGatewayError(closeGateway);
                        }
                    }
                }
            });

            if(hasOpenGatewayValidations) {
                graphValidations.push({ type: 'text', result: 'global.bpm.validation.opengatewayInOutBound' });
            }

            if(hasCloseGatewayValidations) {
                graphValidations.push({ type: 'text', result: 'global.bpm.validation.closegatewayInOutBound' });
            }

            if(graphValidations.length != 0) {
                factory.validations.push({ type: 'graph', result: graphValidations });
            }
        };

        //endregion

        //region Model Validations

        factory.validateModel = function() {
            factory.validations = [];

            if(factory.ABMComponentFactory && factory.ABMComponentFactory.graph && factory.ABMComponentFactory.scope) {
                factory.validateBPM();

                ABMModelFactory.sanitizeModel();
                var model = factory.ABMComponentFactory.scope.model;

                var lang1 = Language.getByColumn('language1', true);
                var lang2 = Language.getByColumn('language2', true);
                var lang3 = Language.getByColumn('language3', true);

                //region Procedure

                var procedureValidations = [];

                if(!existByType(ABMShapesFactory.TEF) && !existByType(ABMShapesFactory.TFF)) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.validation.endRequired' });
                }

                if(lang1.acronym && !model.language1) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredFieldLanguage', resultParameters: { field: $filter('translate')('global.literals.name'), language: lang1.label } });
                }

                if(lang2.acronym && !model.language2) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredFieldLanguage', resultParameters: { field: $filter('translate')('global.literals.name'), language: lang2.label } });
                }

                if(lang3.acronym && !model.language3) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredFieldLanguage', resultParameters: { field: $filter('translate')('global.literals.name'), language: lang3.label } });
                }

                if(lang1.acronym && !model.descriptionLanguage1) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredFieldLanguage', resultParameters: { field: $filter('translate')('global.literals.description'), language: lang1.label } });
                }

                if(lang2.acronym && !model.descriptionLanguage2) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredFieldLanguage', resultParameters: { field: $filter('translate')('global.literals.description'), language: lang2.label } });
                }

                if(lang3.acronym && !model.descriptionLanguage3) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredFieldLanguage', resultParameters: { field: $filter('translate')('global.literals.description'), language: lang3.label } });
                }

                if(!model.maxDays) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredField', resultParameters: { field: $filter('translate')('global.literals.days') } });
                } else if (model.maxDays < 1) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.minValue', resultParameters: { field: $filter('translate')('global.literals.days'), value: 1 } });
                }
                
                if(!model.expireType) {
                	procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredField', resultParameters: { field: $filter('translate')('global.commonAdmin.modal.unguidedProcedure.expireType') } });
                }

                if(model.acronym && model.acronym.length > 5) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.maxLength', resultParameters: { field: $filter('translate')('global.literals.acronym'), value: 5 } });
                }

                if(!model.family || !((model.family.$selected && model.family.$selected.id) || model.family.id)) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredField', resultParameters: { field: $filter('translate')('global.literals.family') } });
                }

                if(!model.showSubject) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredField', resultParameters: { field: $filter('translate')('global.commonAdmin.modal.unguidedProcedure.showSubject') } });
                }

                if(!model.showClassification) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredField', resultParameters: { field: $filter('translate')('global.commonAdmin.modal.unguidedProcedure.showClassification') } });
                }

                if(!model.active) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredField', resultParameters: { field: $filter('translate')('global.literals.active') } });
                }

                if(!model.procedureStartProfiles) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredField', resultParameters: { field: $filter('translate')('global.literals.profiles') + ' ' + $filter('translate')('global.literals.initialize') } });
                } else if(model.procedureStartProfiles.length == 0) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredField', resultParameters: { field: $filter('translate')('global.literals.profiles') + ' ' + $filter('translate')('global.literals.initialize') } });
                }

                if(model.showClassification && model.showClassification != 'DISABLED') {
                    if(!model.procedureClassifications) {
                        procedureValidations.push({ type: 'text',result: 'global.bpm.modelvalidation.requiredField', resultParameters: { field: $filter('translate')('global.literals.classification') } });
                    } else if (model.procedureClassifications.length == 0) {
                        procedureValidations.push({ type: 'text',result: 'global.bpm.modelvalidation.requiredField', resultParameters: { field: $filter('translate')('global.literals.classification') } });
                    }
                }

                if(!model.allowUserChangeMaxDays) {
                    procedureValidations.push({ type: 'text', result: 'global.bpm.modelvalidation.requiredField', resultParameters: { field: $filter('translate')('global.literals.allowUserChangeMaxDays') } });
                }

                if(!model.transactions) {
                    procedureValidations.push({ type: 'text',result: 'global.bpm.modelvalidation.startEndTransaction' });
                } else if(Object.keys(model.transactions).length < 2) {
                    procedureValidations.push({ type: 'text',result: 'global.bpm.modelvalidation.startEndTransaction' });
                } else {
                    var countStart = 0;
                    var countEnd = 0;

                    _.forOwn(model.transactions, function (value) {
                        switch (value.tramitationType) {
                            case 'INITIAL':
                                countStart++;
                                break;
                            case 'FINAL':
                                countEnd++;
                                break;
                        }
                    });

                    if(countStart < 1 || countEnd < 1) {
                        procedureValidations.push({ type: 'text',result: 'global.bpm.modelvalidation.startEndTransaction' });
                    }
                }

                if(procedureValidations.length != 0) {
                    factory.validations.push({ type: 'procedure', result: procedureValidations });
                }

                //endregion

                //region Transactions

                if(model.transactions) {
                    _.forOwn(model.transactions, function(value, key) {
                        var cell = factory.ABMComponentFactory.graph.getCell(key);

                        var transactionValidations = [];

                        if(ABMShapesFactory.getShapeType(cell) != ABMShapesFactory.TI && countInLinksById(key) < 1) {
                            transactionValidations.push({ type: 'text', result: 'global.bpm.validation.transactionInBound' });
                        }

                        if(ABMShapesFactory.getShapeType(cell) == ABMShapesFactory.TI && countOutLinksById(key) != 1) {
                            transactionValidations.push({ type: 'text', result: 'global.bpm.validation.startOutBound' });
                        }

                        if(_.contains([ABMShapesFactory.TEN, ABMShapesFactory.TFN, ABMShapesFactory.SN, ABMShapesFactory.SFN], ABMShapesFactory.getShapeType(cell)) && countOutLinksById(key) != 1) {
                            transactionValidations.push({ type: 'text', result: 'global.bpm.validation.transactionOutBound' });
                        }

                        if(!_.contains([ABMShapesFactory.SN, ABMShapesFactory.SFN], ABMShapesFactory.getShapeType(cell))) {
                            if (lang1.acronym && !value.language1) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.requiredFieldLanguage',
                                    resultParameters: {
                                        field: $filter('translate')('global.literals.name'),
                                        language: lang1.label
                                    }
                                });
                            }

                            if (lang2.acronym && !value.language2) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.requiredFieldLanguage',
                                    resultParameters: {
                                        field: $filter('translate')('global.literals.name'),
                                        language: lang2.label
                                    }
                                });
                            }

                            if (lang3.acronym && !value.language3) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.requiredFieldLanguage',
                                    resultParameters: {
                                        field: $filter('translate')('global.literals.name'),
                                        language: lang3.label
                                    }
                                });
                            }

                            if (!value.maxDays) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.requiredField',
                                    resultParameters: {field: $filter('translate')('global.literals.days')}
                                });
                            } else if (model.maxDays < 1) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.minValue',
                                    resultParameters: {field: $filter('translate')('global.literals.days'), value: 1}
                                });
                            }
                            
                            if(!value.expireType) {
                            	transactionValidations.push({ 
                            		type: 'text', 
                            		result: 'global.bpm.modelvalidation.requiredField', 
                            		resultParameters: { field: $filter('translate')('global.commonAdmin.modal.unguidedProcedure.expireType') } 
                            	});
                            }

                            if (!value.allowThirds) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.requiredField',
                                    resultParameters: {field: $filter('translate')('global.literals.allowThirds')}
                                });
                            }

                            if (!value.allowExpedientRelations) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.requiredField',
                                    resultParameters: {field: $filter('translate')('global.literals.allowExpedientRelations')}
                                });
                            }

                            if (!value.daysComputeGlobal) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.requiredField',
                                    resultParameters: {field: $filter('translate')('global.literals.daysComputeGlobal')}
                                });
                            }
                            if(ABMShapesFactory.getShapeType(cell) != ABMShapesFactory.TI) {
                                if (!value.assignationTypeTram) {
                                    transactionValidations.push({
                                        type: 'text',
                                        result: 'global.bpm.modelvalidation.requiredField',
                                        resultParameters: {field: $filter('translate')('global.literals.assignationType')}
                                    });
                                } else {
                                    if (value.assignationTypeTram == ABMModelFactory.AT_GUIDED) {
                                        if (!value.processProfiles) {
                                            transactionValidations.push({
                                                type: 'text',
                                                result: 'global.bpm.modelvalidation.requiredField',
                                                resultParameters: {field: $filter('translate')('global.literals.processProfiles')}
                                            });
                                        } else if (value.processProfiles.length == 0) {
                                            transactionValidations.push({
                                                type: 'text',
                                                result: 'global.bpm.modelvalidation.requiredField',
                                                resultParameters: {field: $filter('translate')('global.literals.processProfiles')}
                                            });
                                        }
                                    } else if (value.assignationTypeTram == ABMModelFactory.AT_INHERITED) {
                                        if (!value.userStateInheritance) {
                                            transactionValidations.push({
                                                type: 'text',
                                                result: 'global.bpm.modelvalidation.requiredField',
                                                resultParameters: {field: $filter('translate')('global.literals.userInitialStateInheritance')}
                                            });
                                        }
                                    }

                                }
                            }
                            if (!value.transactionType) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.requiredField',
                                    resultParameters: {field: $filter('translate')('global.literals.transactionType')}
                                });
                            }else{
                                var transactionTypeAux = $linq(ABMModelFactory.getOptions().transactionType).singleOrDefault(undefined, "x=>x.id == "+value.transactionType);
                                if(!transactionTypeAux){
                                    transactionValidations.push({
                                        type: 'text',
                                        result: 'global.bpm.modelvalidation.requiredField',
                                        resultParameters: {field: $filter('translate')('global.literals.transactionType')}
                                    });
                                }else if(model.tramitationTypeProcedure != transactionTypeAux.tramitationType.id){
                                    transactionValidations.push({
                                        type: 'text',
                                        result: 'global.bpm.validation.transactionTypeNoTramitationTypeValue',
                                        resultParameters: {field: $filter('translate')('global.literals.transactionType')}
                                    });
                                }
                            }
                        } else if(_.contains([ABMShapesFactory.SFN], ABMShapesFactory.getShapeType(cell))) {
                            if (!value.subprocedureName1) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.requiredFieldLanguage',
                                    resultParameters: {
                                        field: $filter('translate')('global.literals.name'),
                                        language: lang1.label
                                    }
                                });
                            }

                            if (lang2.acronym && !value.subprocedureName2) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.requiredFieldLanguage',
                                    resultParameters: {
                                        field: $filter('translate')('global.literals.name'),
                                        language: lang2.label
                                    }
                                });
                            }

                            if (lang3.acronym && !value.subprocedureName3) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.requiredFieldLanguage',
                                    resultParameters: {
                                        field: $filter('translate')('global.literals.name'),
                                        language: lang3.label
                                    }
                                });
                            }
                        }else{
                            if(!value.subprocedure) {
                                transactionValidations.push({
                                    type: 'text',
                                    result: 'global.bpm.modelvalidation.requiredField',
                                    resultParameters: {field: $filter('translate')('global.bpm.subprocess')}
                                });
                            }
                        }

                        if(transactionValidations.length != 0) {
                            var transName = value[Language.getActiveColumn()] ? value[Language.getActiveColumn()] : key;

                            factory.validations.push({ type: 'transaction', name: transName, result: transactionValidations });

                            ABMShapesFactory.setTransactionError(factory.ABMComponentFactory.getCell(value.graphNodeId));
                        } else {
                            ABMShapesFactory.unsetTransactionError(factory.ABMComponentFactory.getCell(value.graphNodeId));
                        }
                    });
                }

                //endregion
            }

            return false;
        };

        //endregion

        return factory;
    }]);