/**
 * Created by osirvent on 03/08/2016.
 */
var BoxDefinition = function(title, loggedUser, originType) {
    this.title = title;
    this.new = undefined;
    this.search = undefined;
    this.placeholderSearch = '';
    this.searchedValue = { id: -1, object: {}, value: ''};
    this.advancedSearch = undefined;
    this.advancedSearchModalDefinition = undefined;
    this.typeAheadSelect = undefined;
    this.searchAdd = function () {
        if(this.searchedValue.id && this.searchedValue.id != -1) {
            var toAdd = JSOG.decode(this.searchedValue.object);
            this.content.push(toAdd);
            this.searchedValue = { id: -1, value: ''};
            if(this.saveSearch) {
                this.saveSearch(toAdd);
            }
        }
    };
    this.searchButtonText = 'global.literals.add';
    this.content = [];
    this.saveSearch = undefined;
    this.loggedUser = loggedUser;
    this.originType = originType;
    this.viewPermission = undefined;
    this.newPermission = undefined;
    this.addNew = function(newFunc,completedNewFunc) {
        this.new = newFunc;
        this.completedNew = completedNewFunc;
        return this;
    }

    this.addViewAndNewPremissions = function(viewPermission, newPermission) {
        this.viewPermission = viewPermission;
        this.newPermission = newPermission;
    }

    this.removeNew = function() {
        this.new = undefined;
        this.completedNew = undefined;
        return this;
    }

    this.addSearch = function (origin, type, placeholderSearch, processResultFunc, RestService, custom_filter, added_filter, additional_filter, byEntryType, entryType) {
        this.search = function (val) {
            var addedFilter = "x => x.id";
            if(added_filter){
                addedFilter = added_filter;
            }
            var added = $linq(this.content)
                .select(addedFilter)
                .toArray();

            var filter =  { name: val.val };
            if(custom_filter) {
                var parseable_custom_filter = custom_filter.replace('@VAL@', val.val);
                parseable_custom_filter = parseable_custom_filter.replace('@VAL@', val.val);
                filter = JSON.parse(parseable_custom_filter);
            } else {
                if (origin == 'reg') {
                    filter = {entryNumber: val.val};
                    if(byEntryType) {
                        filter.entryType = entryType;
                    }
                }
            }
            if(additional_filter){
                additional_filter.loggedUser = loggedUser;
                if (additional_filter.entityId) {
                	added.push(additional_filter.entityId);
                }
                additional_filter.added = added;
            }else{
                additional_filter = {
                    loggedUser: loggedUser, added: added
                };
            }
            return RestService.filterData(origin,type,filter, additional_filter, 0, 10, '', 3)
                .then(function (data) {
                    var response = [];

                    angular.forEach(data.data.content, function(value, key) {
                        response.push({ id: value.id, object: JSOG.encode(value), value: processResultFunc(value) });
                    });

                    response = new SortedArray(response, 'value').sort();

                    return response;
                });
        }

        this.placeholderSearch = placeholderSearch;

        return this;
    }

    this.addAdvandedSearch = function (advancedModalDefinition, AnnexaModalFactory, added_filter, additional_filter) {
        this.advancedSearch = function () {
            var addedFilter = "x => x.id";
            if(added_filter){
                addedFilter = added_filter;
            }
            var added = $linq(this.content)
                .select(addedFilter)
                .toArray();
            
            if (additional_filter) {
            	if (additional_filter.entityId) {
            		added.push(additional_filter.entityId);
            	}
                if (additional_filter.assignedTask) {
                	advancedModalDefinition.assignedTask = additional_filter.assignedTask;
                }            	
            }

            advancedModalDefinition.added = added;

            AnnexaModalFactory.showBoxAdvancedFilter(advancedModalDefinition);
        };

        this.advancedSearchModalDefinition = advancedModalDefinition;

        return this;
    }

    this.addSearchAdd = function (modalDef, processModalBody, completeAddFunc, AnnexaFormlyFactory, searchAddButtonText, checkOpenModalFunc) {
        this.searchAdd = function () {
            if(this.searchedValue.id && this.searchedValue.id != -1) {
                if(modalDef) {
                    var openModal = true;

                    if(checkOpenModalFunc) {
                        openModal = checkOpenModalFunc(this.searchedValue, this);
                    }

                    var modal = modalDef;
                    modal.size = 'modal-md';
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = processModalBody(this.searchedValue);
                    modal.annexaFormly.options = {};
                    if(!this.content && this.modal && this.modal.boxDefinition && this.modal.boxDefinition.content){
                        modal.annexaFormly.options.formState = {readOnly: false, data: this.modal.boxDefinition.content};
                    }else{
                        modal.annexaFormly.options.formState = {readOnly: false, data: this.content};
                    }
                    modal.extra = this;

                    if(openModal) {
                        AnnexaFormlyFactory.showModal("modalSearchAdd", modal, completeAddFunc, false);
                    } else {
                        completeAddFunc(modal);
                    }
                }
                else{
                    completeAddFunc(this);
                }
            }
        };

        if(searchAddButtonText) {
            this.searchButtonText = searchAddButtonText;
        }

        return this;
    }
}

var BoxAdvancedSearchModalDefinition = function(id, title, tFilter, tabledataDefinition, tabledataColumns, boxDefinition, addFilterCall, externalAdd, externalObject) {
    this.id = id;
    this.title = title;
    this.firstTime = true;
    this.size = '';
    this.content = boxDefinition ? boxDefinition.content : undefined;
    this.boxDefinition = boxDefinition;
    this.tFilter = tFilter;
    this.tabledataDefinition = tabledataDefinition;
    this.tabledataColumns = tabledataColumns;
    this.addElementToTable = boxDefinition ? boxDefinition.searchAdd : undefined;
    this.addFilterCall = addFilterCall;
    this.externalAdd = externalAdd;
    this.externalObject = externalObject;

    this.changeSize = function (size) {
        this.size = size || '';

        return this;
    }
}

